.content {
  margin-bottom: 10px;
}
.block {
  line-height: 30px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.04);

  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  margin-top: 1px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: auto;
  text-align: center;
  color: #5c6a70;

  border-radius: 10px;
}
.image {
  width: 100%;
  height: 130px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-color: #d7d7d7;

  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  /* border-radius: 10px; */
  margin-top: 1px;
  overflow: hidden;
  position: relative;
}
.text {
  padding: 10px;
  color: #5c6a70;
  background-color: rgba(0, 0, 0, 0.04);
}
.blockImage {
  border-radius: 10px;
  overflow: hidden;
}
