.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  border-radius: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #0e526d;
  background-color: #e1e8e8;
  border-color: transparent;
  font-size: 14px;
  outline: 0 !important;
}
.btn:hover {
  text-decoration: none;
  background-color: #cfdada;
  color: #023346;
}
.btn:active {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 172, 180, 0.4);
  box-shadow: 0 0 0 2px rgba(28, 172, 180, 0.4);
}
.radio {
  padding: 4px 9px;
  font-weight: 500;
  border-radius: 10px;
  font-size: 12px;
  background-color: #e6ecec;
}
.radioActive {
  color: #023346;
  background-color: #cfdada;
}
.activeRadio {
  background-color: #d3e6e6;
}

.btnSecondary {
  color: #fff;
  background-color: #009fc1;
  border-color: #009fc1;
}
.btnSecondary:active,
.btnSecondary:hover {
  color: #fff;
  background-color: #00758e;
  border-color: #006d84;
}
