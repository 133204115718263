.dropDownBlock {
  position: absolute;
  z-index: 3;
  top: 5px;
}
.dropdownConstant {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: rgb(255, 255, 255);

  color: gray;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(219, 219, 219);
  white-space: nowrap;
  padding: 0px 5px 2px 5px;
  font-size: 12px;
  border-radius: 4px;
  user-select: none;
}
.dropdownConstant:hover {
  color: rgb(105, 105, 105);
  border-color: #31c17960;
}
.dropdownConstant:active {
  color: rgb(66, 66, 66);
  border-color: #3de89260;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  vertical-align: baseline;
  position: relative;
  top: -2px;
  transition: all 0.1s ease-in-out;
}
.caretAnimate {
  top: -3px;
  transform: rotate(180deg);
}
