.wayNot {
  margin-bottom: 24px;
  margin-top: 8px;

  width: 100%;
  height: 39px;
  padding: 8px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d6e3e3;
  border-radius: 4px;

  display: block;
  font-size: 15px;
  line-height: 1.42857143;
  color: #555;
}

.wordNo {
  color: #999;
}
.circle {
  background-color: #f86850;

  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;

  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 2px;
  margin-top: -2px;
  vertical-align: baseline;
}
.addCnd {
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 15px;
  border-radius: 4px;
  user-select: none;
  width: 100%;
  display: inline-block;
  background-color: rgba(11, 153, 178, 0.05);
  color: #0b99b2;
  border: 1px dashed #0b99b280;
}
.addCnd:hover {
  background-color: rgba(11, 153, 178, 0.149);
}
.addCnd:active {
  background-color: rgba(11, 153, 178, 0.092);
}
