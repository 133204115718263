
.bot__triger-add{
    margin-bottom: 25px;
}
.structure__trigers-create p{
    margin-bottom: 15px;
}
#chainsFrame{
    overflow-x: hidden;
    max-height: 300px;
}
.template-dilogs-btn{
    margin: 10px 5px;
    margin-right: 50px;
    max-width: 135px;
    width: 100%;
    display: block;
    padding: 10px 15px;
    color: #000;
    text-align: center;
    background: transparent;
    border: 1px dashed #009ec14f;
    font-size: 12px;
    cursor: pointer;
}

.template-dilogs-btn__active {
    box-shadow: 0px 0px 7px rgb(49 193 120);
    border: none;
    background: #e5fff28f;
}
.structure__trigers-create p:first-child {
    display: none;
 }
 .structure__trigers-create,
 .scenario-body .tabs-panel{
    width: 100% !important;
 }
@media screen and (max-width: 991px) {
    #chainsFrame{
        max-height: 200px;
    }
    .tabs-panel{
        width: 100%;
    }
    .structure__trigers-create{
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
    }
    .structure__trigers-create p{
        display: none;
    }
    .structure__trigers-create p:first-child {
       display: block;
    }
   
}


@media screen and (max-width: 576px) {
    #chainsFrame{
        max-height: 100px;
    }
    .structure__trigers-create{
        flex-direction: column;
    }
    .date-create-and-update{
        flex-direction: column;
        align-items: flex-start;
    }
    .date-create-and-update small{
        margin-bottom: 10px;
    }
    .date-create-and-update small:last-child{
        margin-bottom: 50px;
    }
    .btn_editchain{
        left: 15px !important;    
    }
}