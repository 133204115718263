.allJson .json-view > span:not([class]) {
  display: none;
}
.allJson .json-view--property {
  color: #716064;
  background-color: #eaeaea;
  border-radius: 8px;
  display: inline-block;
  padding: 3px 6px;
}

.allJson .json-view--index {
  color: #716064;
  background-color: #eaeaea;
  border-radius: 8px;
  display: inline-block;
  padding: 3px 6px;
}

.allJson .json-view--property:has(~ .json-view--string),
.allJson .json-view--property:has(~ .json-view--number),
.allJson .json-view--property:has(~ .json-view--boolean) {
  color: #008aa8;
  background-color: rgba(0, 159, 193, 0.1);
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
}

.allJson .jv-indent {
  padding-top: 4px;
}
.allJson .json-view--string,
.allJson .json-view--number,
.allJson .json-view--boolen,
.allJson .json-view-- {
  color: #91a4a5;
  font-size: 12px;
}
.allJson > .json-view > .jv-size-chevron {
  display: none;
}
/* ------- */
.arrJson .json-view > span:not([class]) {
  display: none;
}
.arrJson .json-view--property {
  color: #008aa8;
  background-color: rgba(0, 159, 193, 0.1);
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
}

.arrJson .json-view--index {
  color: #716064;
  background-color: #eaeaea;
  border-radius: 8px;
  display: inline-block;
  padding: 3px 6px;
}

.arrJson .json-view--property:has(~ .json-view--string),
.arrJson .json-view--property:has(~ .json-view--number),
.arrJson .json-view--property:has(~ .json-view--boolean) {
  color: #716064;
  background-color: #eaeaea;
  border-radius: 8px;
  display: inline-block;
  padding: 3px 6px;
}

.arrJson .jv-indent {
  padding-top: 4px;
}
.arrJson .json-view--string,
.arrJson .json-view--number,
.arrJson .json-view--boolen,
.arrJson .json-view-- {
  color: #91a4a5;
  font-size: 12px;
}
.arrJson > .json-view > .jv-size-chevron {
  display: none;
}
