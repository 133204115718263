.block {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  border-collapse: separate;
  position: relative;
}
.title {
  border: 1px solid #d6e3e3;
  color: #5c6a70;
  background-color: transparent;
  border-color: #d6e3e3;
  font-weight: 600;
  margin-right: -2px;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}
.text {
  width: 100%;
}
.textInvalid {
  border-color: #c13131 !important;
  outline: 0 !important;
}
