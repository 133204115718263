.button {
  position: relative;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #fff;
  height: 30px;
  width: 100%;
  line-height: 30px;
  color: #000;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  font-size: 13px;
  margin: 5px 0;
  border: 1px solid rgba(192, 212, 194, 0.415);
}
.link {
  color: rgb(59, 151, 227);

  stroke-width: 2.7;

  width: 16px;
  height: 16px;

  position: absolute;
  top: calc(50% - 8px);
  right: 5px;
}
