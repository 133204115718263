.channels-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .channels-table__header {
    background-color: #f8f9fa;
  }
  
  .channels-table th,
  .channels-table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    vertical-align: middle;
    text-align: center; 
  }
  
  .channels-table__row:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
.channels-table__row .subscribers{
  font-size: 18px;
}