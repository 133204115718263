.workspace{
    flex: 7;
    padding: 0 50px;
    position: relative;
    overflow: hidden;
    
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.workspace::after{
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -42%;
    right: -65%;
    background-image: url('../../../../public/Ellipse4.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
}
.workspace::before{
    content: '';
    position: absolute;
    z-index: -1;
    top: -50%;
    left: -55%;
    background-image: url('../../../../public/Ellipse3.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
}


@media screen and (max-width: 1200px) {
    .workspace{
        flex: 6;
    }
}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 576px) {

}