.boxUrl {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}
.label {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  font-size: 14px;
}
.body {
  background-color: #e6ecec;
  border-radius: 10px;
  padding: 10px 10px 0px 10px;
}
.btnsBody {
  display: flex;
  column-gap: 10px;
  padding-bottom: 10px;
}
