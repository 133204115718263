
.trigger-words{
    display: flex;
    margin-top: 15px;
}

.trigger-words__elem {
    position: relative;
    font-size: 11px;
    padding: 5px;
    padding-right: 20px;
    margin-right: 10px;
    border: 1px solid #eeeff2b4;
    border-radius: 5px;
    background: #eeeff26b;
}
.trigger-words__elem-example {
    font-size: 11px;
    padding: 5px;
    border: 1px solid #eeeff2b4;
    border-radius: 5px;
    background: #eeeff26b;
}
.trigger-words .delete-link-btn::after {
    content: '\2716';
    position: absolute;
    width: 10px;
    height: 10px;
    top: -12px;
    right: -15px;
    color: #206a79;
}
.time-select{
    max-width: 100px;
}
.invalid-field {
    border: 2px solid red;
  }
  .ignore-checkbox{
    display: flex;
  }