.addButton {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 15px;
  border: 1px dashed #d6e3e3;
  box-shadow: none;
  font-weight: 400;
  color: #688fa1;
  outline: 0;
  background-color: #fbfcfc;
  transition-duration: 0.25s;
  padding-bottom: 12px;
  padding-top: 10px;
  margin: -1px 0;
  border-collapse: collapse;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}
.addButton:hover {
  background-color: #f5f7f7;
}
