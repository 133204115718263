.box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.label {
  margin-bottom: 5px;
  display: block;
  color: rgb(31, 31, 31);
  font-weight: 600;
}
.subLabel {
  font-weight: 500;
  color: rgb(156, 156, 156);
  font-size: 12px;
}
