.dropZone {
  min-height: 140px;
  padding: 48px 24px;
  background: #f6fcff;
  margin-bottom: 0;
  border: 1px solid rgba(11, 153, 178, 0.1);
}
.inputURL {
  width: 100%;
}
