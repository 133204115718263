.bot-name{
    color: #6BE955;
    font-size: 20px;
    margin-bottom: 25px;
}
.bot-name span{
color: #fff;
}

.scenario-menu-list{
    display: flex;
}
.scenario-menu .bot-name{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.scenario-menu .bot-name p {
    margin-left: 10px;
}
.scenario-menu__item {
    display: block;
    position: relative;
    padding: 10px 25px;
    margin-right: 15px;
    border-radius: 5px 5px 0 0;
    border-right: 1px solid #2A2929;
    border-top: 1px solid #2A2929;
    border-left: 1px solid #2A2929;
    background: #f3f3f34d;
    color: #fff;
}
.scenario-menu__item-link{
    font-weight: bold;
    font-size: 12px;
}
.scenario-menu__item--active{
    background-color: #2A2929;
}
.scenario-menu__item-link--active{
    background-color: #eeeff2;
    font-weight: bold;
}
.scenario-body{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px 15px;
    background-color: #2A2929;
    border-radius: 0 15px 15px  15px ;
}
.scenario-body .card{
    padding: 15px;
    margin: 15px;
}
.scenario-body .card:first-child{
    width: 30%;
}
.scenario-body .card:last-child{
    width: 68%;
}
.scenario-menu__item:first-child {
    border-radius: 15px 0 0 0 ;
}
.scenario-body .scenario-menu__item {
    padding: 5px 10px;
    font-size: 12px;
}
.scenario-body .table-body{
    width: 100%;
}

.btn_editchain{
    position: absolute;
    z-index: 2;
    top: 150px;
    
    right: 25px;
    padding: 10px 15px;
    -webkit-box-shadow:0 2px 5px rgb(0 159 193 / 43%);
    box-shadow: 0 2px 5px rgb(0 159 193 / 43%);
    border-radius: 4px;
    color: #009fc1;
    background: #ffffff;
    font-weight: bold;
}
.render-content-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  15px 0px 25px 15px;
}
.date-block{
    margin-bottom: 25px;
}
.date-block p{
    margin: 5px 0;
}
.date-create-and-update{
    display: flex;
    align-items: center;
}
.date-create-and-update small {
    display: block;
    margin-right: 10px;
}
.date-create-and-update .fa{
    color: #afafaf;
}






/* 

.scenario-menu-list{
    display: flex;
}
.scenario-menu .bot-name{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.scenario-menu .bot-name p {
    margin-left: 10px;
}
.scenario-menu__item {
    display: block;
    position: relative;
    padding: 10px 25px;
    margin-right: 15px;
    border-radius: 5px 5px 0 0;
    border-right: 1px solid #eeeff2;
    border-top: 1px solid #eeeff2;
    border-left: 1px solid #eeeff2;
    background: #f3f3f34d;
    color: #0000004d;
}
.scenario-menu__item-link{
    font-weight: bold;
    font-size: 12px;
}
.scenario-menu__item .fa{
    color: #afafaf;
}
.scenario-menu__item--active,
.scenario-menu__item--active .fa{
    background-color: #eeeff2;
    color: #000000;
}
.scenario-menu__item-link--active{
    background-color: #eeeff2;
    font-weight: bold;
}
.scenario-body{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px 15px;
    background-color: #eeeff2;
    border-left: 1px solid #eeeff2;
    border-right: 1px solid #eeeff2;
    border-bottom: 1px solid #eeeff2;
    border-top: 1px solid #eeeff2;
    border-radius: 0 5px 5px  5px ;
}
.scenario-body .card{
    padding: 15px;
    margin: 15px;
}
.scenario-body .card:first-child{
    width: 30%;
}
.scenario-body .card:last-child{
    width: 68%;
}
.scenario-body .scenario-menu__item {
    padding: 5px 10px;
    font-size: 12px;
}

.btn_editchain{
    position: absolute;
    z-index: 2;
    top: 150px;
    
    right: 25px;
    padding: 10px 15px;
    -webkit-box-shadow:0 2px 5px rgb(0 159 193 / 43%);
    box-shadow: 0 2px 5px rgb(0 159 193 / 43%);
    border-radius: 4px;
    color: #009fc1;
    background: #ffffff;
    font-weight: bold;
}
.render-content-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  15px 0px 25px 15px;
}
.date-block{
    margin-bottom: 25px;
}
.date-block p{
    margin: 5px 0;
}
.date-create-and-update{
    display: flex;
    align-items: center;
}
.date-create-and-update small {
    display: block;
    margin-right: 10px;
}
.date-create-and-update .fa{
    color: #afafaf;
}


@media screen and (max-width: 1200px) {
   
    .scenario-body .card:first-child,
    .scenario-body .card:last-child{
        width: 100%;
    }
}


@media screen and (max-width: 576px) {

    .scenario-menu__item{
        padding: 5px 10px;
        margin-right: 5px;
    }
    .filter-check{
        flex-direction: column;
    }
    

} */