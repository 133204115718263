.box {
  padding: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
  position: relative;
  border: 1px solid rgba(39, 192, 226, 0.1);
  background: #f6fcff;
  border-radius: 8px;
}

.union {
  width: 100%;
}

.label {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  margin-bottom: 6px;
}
