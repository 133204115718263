.block {
  width: 230px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.813);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: 5px;
}

.blockSelected {
  border-color: #31c178;
  box-shadow: 0 0 0 1px #31c178, 0 0 0 4px rgba(49, 193, 120, 0.3);
}

.title {
  cursor: grab;
  display: flex;
  justify-content: center;
  padding: 2px 5px 5px 5px;
  color: #fafafa;
  background-color: #cc586c;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}

.contentBlock {
  text-align: center;
  padding: 6px 4px;
  color: #465152;
  font-size: 15px;
}

.subtitle {
  padding: 2px;
  margin: 5px 0;
  color: #716064;
  word-wrap: break-word;
  background-color: #eaeaea;
  border-radius: 8px;
  text-align: center;
}

.outputBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 6px;
}

.output {
  padding: 3px 26px 3px 10px;
  font-size: 13px;
  font-weight: 400;
  border: 1px #cdd4d4 solid;
  border-radius: 20px;
}

.imageBox {
  padding: 6px;
  text-align: center;
  font-size: 14px;
  color: rgba(70, 81, 82, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.04);
  min-height: 100px;
  word-wrap: break-word;
}

.dataBox {
  border-radius: 10px;
  word-wrap: break-word;
  overflow: hidden;
}

.textBox {
  min-height: 90px;
  padding: 10px;
  word-wrap: break-word;
  background-color: rgba(247, 247, 247, 0.93);
}

.text {
  word-wrap: break-word;
  font-size: 13px;
  font-weight: 500;
}

.description {
  font-size: 12px;
  word-wrap: break-word;

  font-weight: 400;
}

.storeValuesBox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2px;
}

.storeValue {
  display: inline-block;
  font-size: 10px;
  padding: 2px 4px;
  word-wrap: break-word;
  text-align: center;
  width: 50%;
}
.valuePath {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  color: #023346;
  background-color: #d9eef8;
}
.valueConstant {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  color: #eb8500;
  background-color: #fadbbe;
}
.storeValuesBox {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  width: 100%;
}
