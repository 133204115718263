.title {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}
.radio {
  margin-bottom: 5px;
  user-select: none;
}
.condition {
  display: block;
  font-size: 15px;
  line-height: 1.42857143;
  color: #555;
  width: 100%;
  height: 39px;
  padding: 8px 12px;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d6e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.condition:focus {
  border-color: #31c178;
  outline: 0 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
}
.calendar::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 1;
  background: url("../../../../icon/calendar.svg") no-repeat center;
  background-size: contain;
}
.time::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 1;
  color: #097d91;
  background: url("../../../../icon/time.svg") no-repeat center;
  background-size: contain;
}
.blockDate {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 67%;
  margin-top: 10px;
}
.itemData:nth-child(1) {
  flex-basis: 55%;
}
.itemData:nth-child(2) {
  flex-basis: 40%;
}
.utc {
  color: #9db5c4;
  font-size: 86%;
  white-space: nowrap;
}
.blockTime {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-top: 10px;
  width: 60%;
}
.itemTime:nth-child(1) {
  flex-basis: 50%;
}
.blockContinue {
  margin-top: 20px;
}
.textRadio {
  margin: 0 0 10.5px;
  margin-top: 2px;
  line-height: 1.15;
  padding-left: 18px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #b9b9b9;
}
