.block {
  line-height: 30px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.04);

  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-top: 1px;
  margin-bottom: 10px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: auto;
  text-align: center;
  color: #5c6a70;
  text-overflow: ellipsis;

  padding-right: 10px;
}
.text {
  padding-left: 15px;
  padding-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  word-break: break-word;
}
.icon {
  position: relative;
  left: -4px;
  top: 2px;
}
