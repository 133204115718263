.btn {
  border-bottom: 1px dotted;
  color: #0b99b2;
  cursor: pointer;
  padding-bottom: 1px;
  position: relative;
  user-select: none;
  font-size: 13px;
  font-weight: 500;
}
.btn:hover {
  color: #0b8ba2;
  border-color: #0b8ba2;
}
