
.period-filter-container{
    margin-top: 25px;
}
.filter-check{
    display: flex;
    align-items: center;
}
.period-filter-container button:hover{
    opacity: 0.4;
}
.period-filter-button[disabled] {
    opacity: 0.4 !important;
}

.filter-control-btns{
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-control-btns button:first-child{
    background-color: #31c178;
}
.filter-control-btns button:last-child{
    
    background-color: #8b2f10;
}
.period-btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.between-date-container{
    display: flex;
}
.between-date-container .datepicker-input{
    margin: 10px 15px;
}
.period-filter-button{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 250px;
    margin: 10px 15px;
    width: 100%;
    padding: 7px 0;
    border-radius: 4px;
    background-color: #383f49;
    color: #ffffff;
    cursor: pointer;
}

@media screen and (max-width: 576px) {

    .period-filter-button{
        margin: 5px 10px;
    }
    .filter-check p{
        margin-top: 15px;
    }
}