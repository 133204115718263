.schalude-mail-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    background-color: #f2f5f5d4;
    margin: 5px 0;
    
    transition: all 0.3s ease;
}
.schalude-mail-container:not(:first-child):hover{
    cursor: pointer;
}
.schalude-mail-container:first-child{
    margin-top: 25px;
    opacity: 1;
}
.schalude-mail-container p{
    width: 33%;
    
}
.schalude-mail-container:not(:first-child):hover{
    opacity: 0.6;
}
.schedule-mailing-params p{
    margin: 10px 0;
}
@media screen and (max-width: 576px) {

    .schalude-mail-container{
        width: 200%;
    }
}