.add-bot-btn{
  margin-left: 0;
  margin-right: auto;
}
.bots-card{
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 25px;
  border-radius: 15px;
  background-color: #343333;
}
.title-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.line .title-wrapper{
  padding-bottom: 25px;
}
.line{
  position: relative;
}
.line:after{
  content: '';
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #454545;
}
.empty-bots{
  margin-top: 25px;
  font-weight: bold;
}
.empty-bots span{
  display: inline-block;
}
.empty-bots span::after, .get-back::after{
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(148.62deg, rgb(107, 233, 85), rgb(124, 222, 205));
}
.empty-bots span, .get-back{ 
  position: relative;
  background: linear-gradient(148.62deg, rgb(107, 233, 85), rgb(124, 222, 205));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-decoration: underline;
}
.checkbox-group label{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
  
.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.checkbox-input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.checkbox-box {
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.checkbox-check {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.checkbox-input[type="checkbox"]:checked + .checkbox-box .checkbox-check {
  width: 13px;
  height: 13px;
  background-image: url('/public/check.png');
  background-repeat: no-repeat;
  background-position: center;
}
.table-body{
  max-height: 400px;
  overflow-y: auto;
}
.bot-management {
  width: 100%;
  table-layout: fixed;
}

.bot-management  td div{
  max-width: 110px;
  width: 100%;
}
.bot-management  td div:first-child{
  margin-right: 5px;
}
.bot-management  th{
  opacity: 0.45;
  font-size: 12px;
}
.bot-management  td{
  font-size: 12px;
  display: flex;
}
.bot-management td p{
  margin-left: 20px;
}
.bot-management  td div button{
  width: 100%;
  margin-right: 5px;
  margin-bottom: 5px;
}

.voronka-bots th:nth-child(1),
.voronka-bots td:nth-child(1),
.helper-bots th:nth-child(1),
.helper-bots td:nth-child(1),
.draw-bots th:nth-child(1),
.draw-bots td:nth-child(1)  {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.voronka-bots th:nth-child(2),
.voronka-bots td:nth-child(2),
.helper-bots th:nth-child(2),
.helper-bots td:nth-child(2),
.draw-bots th:nth-child(2),
.draw-bots td:nth-child(2)  {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.voronka-bots th:nth-child(3),
.voronka-bots td:nth-child(3),
.helper-bots th:nth-child(3),
.helper-bots td:nth-child(3),
.draw-bots th:nth-child(3),
.draw-bots td:nth-child(3) {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.voronka-bots th:nth-child(4),
.voronka-bots td:nth-child(4),
.draw-bots th:nth-child(4),
.draw-bots td:nth-child(4) {
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.voronka-bots th:nth-child(5),
.voronka-bots td:nth-child(5),
.draw-bots th:nth-child(5),
.draw-bots td:nth-child(5) {
  width: 220px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bot-management a{
  text-decoration: underline;
  cursor: pointer;
  color: #6BE955;
  font-size: 12px;
}

.bot-management tr {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 5px;
  position: relative;  
}
.draw-bots tr{
  margin-bottom: 50px;
}
.draw-managment-btn {
  position: absolute;
  bottom: -30px;
  right: 0;
  margin: 5px;
  margin-right: 0;
  padding: 10px;
  background-color: #525cdd;
  border-radius: 0px 0px 5px 5px;
  color: #fff;
  width: 100%;
  max-width: 200px;
  box-shadow: 0 4px 24px rgb(82 92 221 / 22%);
}

.draw-bots tr td:nth-child(2) p {
  width: 100px;
  overflow: hidden;
}
.connected, .disconnected{
  position: relative;
  animation: pulse 2.5s infinite;
}
@keyframes pulse{
  0% {
    opacity: 1;
  }
  50% {
      opacity: .6;
  }
  100% {
      opacity: 1;
  }
}
.disconnected{
  color: #F94646;
}

.connected::after, .disconnected::after{
  content: '';
  position: absolute;
  left: -20px;
  top: 2px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  font-size: 11px;
}
.connected::after{
  background-image: url('/public/connected.png');
}
.disconnected::after{
  background-image: url('/public/disconnected.png');
}

.btn-connect,
.btn-disconnect{
  background-color: #fff;
}
.btn-scenario{
  background-color: #67F6DC;
}
.btn-delete{
  background-color: #F94646;
  color: #fff;
}
.btn-draw{
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  background-color: #9747FF;
  color: #fff;

}
.btn-connect::after,
.btn-disconnect::after,
.btn-scenario::after,
.btn-delete::after,
.btn-draw::after{
  content: '';
  position: absolute;
  left: 10px;
  top: 8px;
  width: 13px;
  height: 13px;
  margin-right: 10px;
  font-size: 11px;
  background-repeat: no-repeat;
  background-position: center;
}
.btn-connect::after{
  background-image: url('/public/check-b.png');
}
.btn-disconnect::after{
  background-image: url('/public/cross.png');
}
.btn-scenario::after{
  background-image: url('/public/scenario.png');
}
.btn-delete::after{
  background-image: url('/public/delete.png');
}
.btn-draw::after{
  background-size: contain;
  background-image: url('/public/managment.png');
}
.add-bot-title{
  margin-bottom: 15px;
}
.add-bot-form div{
  display: flex;
  align-items: center;
}
.add-bot-form:first-child{
  margin-top: 25px;
}
.add-bot-option{
  max-width: 115px;
  margin-right: 10px;
}
.add-bot-form p{
  margin-right: 20px;
}
.add-bot-option{
  margin-right: 45px !important;
}

.add-token-btn{
  margin-left: 15px;
}
.token-input .input{
  padding-top: 8px;
  padding-bottom: 8px;
}

.admin-name-item{
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 5px;
    max-height: 40px;
    row-gap: 20px;
    font-size: 18px;
}
.bot-name{
  color: #6BE955;
  font-size: 20px;
  margin-bottom: 25px;
}
.bot-name span{
  color: #fff;
}
.scenario-list{
 display: flex;
 
}
.scenario-menu__item {
  display: block;
  position: relative;
  padding: 10px 25px;
  margin-right: 15px;
  border-radius: 5px 5px 0 0;
  border-right: 1px solid #2A2929;
  border-top: 1px solid #2A2929;
  border-left: 1px solid #2A2929;
  background: #2A2929;
}
.scenario-menu__item-link{
  font-weight: bold;
  font-size: 12px;
}

.scenario-menu__item--active{
  background-color: #343333;
}
.scenario-menu__item-link--active{
  background-color: #343333;
  font-weight: bold;
}
.scenario-body{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 15px;
  background-color: #343333;
  border-left: 1px solid #343333;
  border-right: 1px solid #343333;
  border-bottom: 1px solid #343333;
  border-top: 1px solid #343333;
  border-radius: 0 5px 5px  5px ;
}




























/* @media screen and (max-width: 576px) {
    .workspace .card{
      padding-top: 50px;
      padding-bottom: 25px;
    }
   .card:first-child{
      padding-top: 50px;
    }
    .bots-card .form-elem{
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .bots-card .form-elem .checkbox-group{
      margin-top: 15px;
      margin-bottom: 25px;
    }
    .checkbox-group span{
      margin-left: 15px;
    }
    .bot-management{
      width: 270%;
  }
} */