.select {
  border: 1px solid #d6e3e3;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  padding: 7px 12px;
  color: rgb(99, 99, 99);
  border-radius: 4px;
  display: block;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
}
.select > option {
  min-height: 1.2em;
}
.select:focus {
  border-color: #31c178;
  outline: 0 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
}
.fullWidth {
  width: 100%;
}
