.block {
  background-color: #d6e3e3;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 7px 9px;
  border-radius: 6px;
  font-weight: 500;
  position: relative;
}
.select {
  width: auto;
  display: inline-block;
  vertical-align: baseline;

  font-weight: 600;

  color: #0b99b2;
  padding-left: 2px;
  padding-right: 2px;

  border-color: transparent;
  background-color: transparent;
  position: relative;
  top: -1px;
  outline: 0;
  vertical-align: middle;
  -webkit-box-shadow: none;
  box-shadow: none;

  height: 31px;
  border-radius: 3px;
}
.select:focus {
  border-color: #31c178;
  outline: 0 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
}
.icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: calc(50% - 9px);
  right: 5px;
}
