@import url('https://fonts.googleapis.com/css2?family=Onest:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.App {
  box-sizing: border-box;
  text-align: center;
  margin-top: 30px;
}
.react-flow__edge-path,
.react-flow__connection-path {
  stroke: rgb(24, 108, 217) !important;
  stroke-width: 2 !important;
}

/* для инпутов */
/* input[type='text'],
input[type='number'] {
  border: 1px solid #d6e3e3;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  padding: 8px 12px;
  border-radius: 4px;
  display: block;
  font-size: 15px;
}
input[type='text']:focus,
input[type='number']:focus {
  border-color: #31c178;
  outline: 0 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
}
input.errorInput[type='text'],
input.errorInput[type='number'] {
  border: 1px solid #d93024;
}
input.errorInput[type='text']:focus,
input.errorInput[type='number']:focus {
  border: 1px solid #d93024;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(193, 49, 49, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(193, 49, 49, 0.2);
} */

.BlockBorder {
  width: 100px;
  height: 100px;
  border: 2px seagreen solid;
  position: absolute;
}
.react-flow__viewport {
  background-color: #eaf9f9;
  width: 3250px !important;
  height: 2250px !important;
}

.dragButton {
  background-color: #31c178;
}
.dragButton::hover {
  background-color: #31c178;
}
.WrapperFlow {
  width: 100%;

  height: calc(100vh - 75px);
  display: flex;
  background: repeating-linear-gradient(
    45deg,
    #eee,
    #eee 10px,
    #e5e5e5 10px,
    #e5e5e5 20px
  );
}
.reactFlowWrapper {
  width: 100vw;
  height: 100%;
  
}
.reactFlowWrapper-lock{

  position: relative;
}
.reactFlowWrapper-lock::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background: #74afcc94;
  width: 100%;
  height: 100vh;
}



.edgebutton {
  border-radius: 50%;
  background-color: rgb(24, 108, 217);
  width: 20px;
  height: 20px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  position: relative;
}
.crossButton {
  position: absolute;
  top: calc(50% - 16.5px);
  left: calc(50% - 6.7px);
  cursor: pointer;
}
.edgebutton:hover {
  transform: scale(1.1, 1.1);
}
