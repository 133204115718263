.block {
  width: 230px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.813);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: 5px;
}
.blockSelected {
  border-color: #31c178;
  box-shadow: 0 0 0 1px #31c178, 0 0 0 4px rgba(49, 193, 120, 0.3);
}
.title {
  cursor: grab;
  display: flex;
  justify-content: center;
  padding: 2px 5px 5px 5px;
  color: #fafafa;
  background-color: #ffb331;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}
.content {
  padding: 6px 4px;
}
