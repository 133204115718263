.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.078);
}

.popup {
  margin: 100px auto;
  padding: 10px;
  max-width: 450px;
  padding: 0 20px 10px 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
}
.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputTitle {
  font-weight: 600;
}
.close {
  /* padding: 2px; */
  font-weight: 600;
  font-size: 2rem;
  height: 60px;
  user-select: none;
  cursor: pointer;
}
.close > h3 {
  color: #5a5a5a;
}
.close > h3:hover {
  color: black;
}

.inputBox {
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.buttonBox {
  padding: 10px;

  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}
