.restored-data-title{
  margin-bottom: 25px;
  padding: 15px;
}
#chainsFrame p:first-child  {
  display: none;
}
@media screen and (max-width: 991px) {

  .restored-data-title{
      display: none;
  }
  #chainsFrame p:first-child {
     display: block;
  }
 
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 576px) {
  #chainsFrame{
      max-height: 100px;
  }
}