.block {
  position: absolute;
  right: 0;
  bottom: -40px;
  padding: 5px;
  border-radius: 6px;
  background: #fff;
  margin: 2px;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.trash {
  width: 15px;
  height: 15px;
  color: rgb(216, 33, 45);
  transform: scale(1.5, 1.5);
  margin: 3px 0 15px 0;
  cursor: pointer;
}
.block:hover {
  background-color: #f0f0f0;
}
