.errorMessage {
  padding: 15px;
  background-color: snow;
  color: #bb364d;
  border-width: 0;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

  margin: 4px 0;
  overflow: hidden;
  position: absolute;
  top: -50px;
  z-index: 10;
  border: 1px solid transparent;
  border-radius: 4px;

  cursor: pointer;

  transform: translateX(500px);
  transition: all 0.6s ease-in-out;
}
.animate {
  transform: translateX(0);
}
