.block {
  padding: 4px;
  width: 90px;
  height: 32px;
  border-radius: 18px;
  background: #31c178;
  color: #fff;
  border: 0;

  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  pointer-events: initial;
  text-align: center;
  font-family: 'Nunito Sans', 'Avenir Next', 'Segoe UI', 'Open Sans',
    'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.title {
  padding-left: 2px;
  padding-right: 20px;
  padding-top: 1px;
  text-align: center;
  font-weight: 600;
}
