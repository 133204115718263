.box {
  position: relative;
}
.input {
  width: 100%;
  color: #555;
}

.error {
  border-color: rgb(223, 65, 65) !important;
}
