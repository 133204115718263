.block {
  margin-top: 32px;
  color: #9db5c4;
  border-top: 1px solid #d6e3e3;
}
.text {
  display: inline-block;
  position: relative;
  background: #f5f7f7;
  top: -13.6px;
  padding-right: 6px;
}
