.block {
  color: #333;
  padding-right: 30px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  column-gap: 10px;
}
.condition {
  display: block;
  font-size: 15px;
  line-height: 1.42857143;
  color: #555;
  width: 100%;
  height: 39px;
  padding: 8px 12px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d6e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.condition:focus {
  border-color: #31c178;
  outline: 0 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
}
.conditionWrapper:nth-child(1) {
  flex-basis: 33.33%;
}
.conditionWrapper:nth-child(2) {
  flex-basis: 27.77%;
}
.conditionWrapper:nth-child(3) {
  flex-basis: 38.9%;
}
.calendar::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 1;
  color: red;
  background: url('../../../../icon/calendar.svg') no-repeat center;
  background-size: contain;
}
.trash {
  right: 5px;
  top: calc(50% - 13px);
  position: absolute;
  width: 15px;
  height: 15px;
  color: #7180a65a;

  transform: scale(1.5, 1.5);
  margin: 3px 0 15px 0;
  cursor: pointer;
}
.trash:hover {
  color: rgb(206, 91, 99);
}
.trashDeactive {
  color: #7180a65a;
  cursor: auto;
}
.trashDeactive:hover {
  color: #7180a65a;
}
