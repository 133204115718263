#authPage .main-title{
    width: 275px !important;
    
}
#authPage .card-title{
    margin: 0 auto;
    margin-top: 30px;
    display: block;
}
.auth-page-inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}
.auth-page__img{
    position: relative;
    background-image: url('../../../public/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}
.auth-page-inner .auth-page__img, .auth-page-inner .auth-page__content{
    position: relative;
    width: 50%;
    background-color: #151515;
    height: 100vh;
    overflow: hidden;
}
.auth-page-inner > .auth-page__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
    color: #fff;
}
.auth-page-inner > .auth-page__content{
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    background-image: url('../../../public/Ellipse2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-page-inner .main-title{
    margin-top: 30px;
}

.auth-page-inner .card{
    position: relative;
    z-index: 999;
    margin-top: 110px;
    background: #252424;
    border-radius: 15px;
    padding: 30px;
}

#authPage .card-title{
    text-align: center;
    color: #fff;
    font-size: 21px;
    margin-bottom: 25px;
}
#authPage .input{
    width: 330px;
}



.auth-inner .card{
    max-width: 350px;
    width: 100%;
    padding: 20px 35px;
}

.auth-page-inner .btns{
    margin-top: 65px;
}
.auth-btn {
    position: relative;
    border-radius: 5px;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    cursor: pointer;
    color: #1D1C1C;
    font-weight: bold;
    transition: all 0.3s ease;
}

.auth-btn::before,
.auth-btn::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: opacity 0.3s ease;
}

.auth-btn::before {
    background: linear-gradient(148.62deg, rgb(171, 171, 171) 11.713%, rgb(253, 253, 253) 81.542%);
    opacity: 0; 
}

.auth-btn::after {
    background: linear-gradient(148.62deg, rgb(107, 233, 85) 11.713%, rgb(124, 222, 205) 81.542%);
    opacity: 1; 
    
}

.auth-btn:hover::before {
    opacity: 1; 
    
}

.auth-btn:hover::after {
    opacity: 0; 
    
}
.reg-acc-btn{
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    
    font-weight: bold;
    transition: all 0.3s ease;
    
}
.reg-acc-btn:hover{
    color: #a0a0a0;
}
.tg-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.auth-tg-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(148.62deg, rgb(107, 233, 85),rgb(124, 222, 205));
    -webkit-background-clip:
    text;
    -webkit-text-fill-color:
    transparent;
    font-weight: bold;
    text-decoration: none;
    margin: 0;
    opacity: 1;
    transition: all 0.3s ease;
}

.auth-tg-btn:hover{
    opacity: 0.5;
}