.box {
  border: 1px solid #cdd4d4;
  border-radius: 5px;
  width: 100%;
  min-height: 33px;
  position: relative;
  display: block;
}
.content {
  background-color: #fff;
  text-align: left;
  position: relative;
  color: #000;
  font-weight: 400;

  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6.5px 12px;
  font-size: 14px;
  border-radius: 8px;

  width: 100%;
}
.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(28, 172, 180, 0.4);
  box-shadow: 0 0 0 2px rgba(28, 172, 180, 0.4);
  border-color: #1cacb4;
}

.muted {
  color: #465152;
  opacity: 0.7;
}

.caret {
  position: absolute;
  height: 10px;
  top: 50%;
  right: 10px;
  margin-top: -2px;
  vertical-align: baseline;

  display: inline-block;
  width: 0;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
