#drawTable tr{
    margin: 25px 0;
}
#drawTable th:nth-child(1), 
#drawTable td:nth-child(1),
#drawTable th:nth-child(3), 
#drawTable td:nth-child(3),
#drawTable th:nth-child(4), 
#drawTable td:nth-child(4),
#drawTable th:nth-child(5), 
#drawTable td:nth-child(5),
#drawTable th:nth-child(6), 
#drawTable td:nth-child(6),
#drawTable th:nth-child(7), 
#drawTable td:nth-child(7){
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#drawTable th:nth-child(2), 
#drawTable td:nth-child(2){
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#drawTable th:nth-child(8), 
#drawTable td:nth-child(8){
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.modal-header{
    text-align: center;
}
.modal-header h2{
    margin: 25px 0;
}
.draw-textarea{
    display: block;
    margin: 15px auto;
    padding: 25px;
    max-width: 600px;
    width: 100%;
    height: 200px;
    border: 1px solid #ccc; /* Граничная линия с цветом */
    border-radius: 4px; /* Закругленные углы */
    resize: none;
}
.draw-textarea:focus {
    border-color: #007bff; /* Изменить цвет граничной линии при фокусировке */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Тень при фокусировке */
  }
.btn_end-draw,
.btn-notify-winners{
    width: 100%;
    margin: 5px 0;
    padding: 10px;
  }
.draw-managment-btn small{
    display: flex;
    align-items: center;
}
.draw-title{
    margin-bottom: 50px;
}
.winners-input{
    position: relative;
    display: flex;
    align-items: center;
}
.winners-input input .btn_disconnect{
    position: absolute;
    top: 0;
    right: 0;
}
.winners-input input,
.winners-input input p,
.winners-input input button{
    margin: 10px;
}
.add-winner-btn{
    margin: 0 auto;
}
.winners-container{
    overflow-y: scroll;
    height: 150px;
}
.winners-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.winners-buttons > .main-btn{
    margin-right: 10px;
    margin-left: 10px;
}