.auditory-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .auditory-table_header {
    background-color: #f8f9fa;
  }
  
  .auditory-table th,
  .auditory-table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    vertical-align: middle;
    text-align: center; 
  }
  
  .auditory-table_row:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .auditory-table_row em{
    display: block;
  }