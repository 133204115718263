.dropZoneBlock {
  position: relative;
}

.dropDownTitle {
  font-size: 14px;
  padding: 0 5px;
  color: #868686;
  white-space: nowrap;
}
