.blockPause {
  min-height: 20px;
  padding: 13px 19px 19px 19px;

  margin-bottom: 20px;
  background-color: #fff;

  position: relative;
}
.blockPauseLast {
  opacity: 0.5;
}

.title {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.inputBlock {
  display: table;
  border-collapse: separate;
  max-width: 100px;
}
.number {
  max-width: 100px !important;

  display: table-cell !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.cell {
  padding: 8px 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #f7f7f9;
  border: 1px solid #d6e3e3;
  border-radius: 4px;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 0;
}
.warning {
  position: relative;
  text-align: left;
  font-size: 13px;
  bottom: -5px;
  color: #688fa1;
  z-index: 1;
}
.iconWarning {
  color: #f28c4b;
  width: 17px;
  height: 17px;
  margin-right: 3px;
  position: relative;
  bottom: -3px;
}
