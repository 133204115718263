.body {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  /* display: none; */
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  border: 0;
  padding: 10px 10px 5px 5px;
  /* max-width: 500px; */
  max-height: 200px;
  min-width: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.tip {
  padding: 10px;
  color: grey;
  font-weight: 600;
  font-size: 12px;
}
