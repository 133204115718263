
.sidebar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    font-size: 16px;
    height: 100%;
    overflow: auto;
    min-height: 0;
    transition: all 0.3s ease;
    background-color: #252424;
  }
  .sidebar-title{
    margin-top: 35px;
    margin-bottom: 50px;
    color: #fff;
    text-align: center;
    font-size: 21px;
  }
  
  .sidebar__menu {
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 100vh;
    padding: 35px 50px;
   
    transition: all 0.3s ease;
  }
  .sidebar__menu-item{
    display: flex;
    align-items: center;
  }
  .sidebar__menu .sidebar__menu-item:last-child{
    margin-top: 300px;
  }
  .sidebar__menu-close{
    left: -250px;
  }
.icon-menu{
    display: none;
}
.sidebar__item-link{
    margin: 10px;
    font-weight: bold;
}
.item-icon {
    width: 27px;
    height: 27px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

}
.item-icon--active {
    background: linear-gradient(148.62deg, rgb(107, 233, 85) 5.8%,rgb(124, 222, 205) 90.861%);
}
.sidebar__item-link--active{
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(148.62deg, rgb(107, 233, 85),rgb(124, 222, 205));
    -webkit-background-clip:
    text;
    -webkit-text-fill-color:
    transparent;
    font-weight: bold;
    text-decoration: none;
    opacity: 1;
    transition: all 0.3s ease;
}
.sidebar__item-link:hover
{
    color: #d6d6d680;
}
.username{
    display: inline;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
}

.admin__btn{
    align-items: center;
    padding: 5px 50px;
    border: 1px solid #0b99b280;
    border-radius: 10px;
    color: #fff;
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(145, 85, 253) 94%);
}
.admin__btn:hover{
    border: 1px solid transparent;
    background-color: rgb(120, 10, 153);
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(120, 10, 153) 94%);
}
.admin__title-col{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: -20px;
}
.admin__name-item{
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 5px;
    max-height: 40px;
    row-gap: 20px;
    font-size: 18px;
}
.admin__btn-ul{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.admin__name-title{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px;
    text-align: center;
}
.admin__name-item + .admin__name-item{
    padding-top: 10px;
}
.admin__name-connect{
    background-color: #48e818;
    border-radius: 4px;
    padding: 2px 13px;
    text-align: center;
    color: #fff;
}
.admin__name-disconnect{
    background-color: #c82333;
    border-radius: 4px;
    padding: 2px 6px;
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.admin__group-img{
    max-width: 30px;
    max-height: 30px;
}

.admin__btn{
    font-size: 16px;
    padding: 7px 5px;
    max-width: 120px;
    border: 0px solid #0b99b280;
    border-radius: 4px;
    color: #fff;
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(145, 85, 253) 94%);
}
.admin__btn:hover{
    border: 0px solid transparent;
    background-image: linear-gradient(98deg, rgb(203, 184, 240), rgb(168, 131, 235) 94%);
}
.admin__btn-connect{
    font-size: 16px;
    padding: 7px 5px;
    max-width: 120px;
    border: 0px solid #0b99b280;
    border-radius: 4px;
    color: #fff;
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(30, 49, 221), rgb(90, 81, 211) 74%);
}
.admin__btn-connect:hover,
.admin__btn-connect:focus{
    border: 0px solid transparent;
    background-color: rgb(68, 82, 209);
    background-image: linear-gradient(98deg, rgb(109, 118, 204), rgb(139, 135, 187) 74%);
}
.admin__btn-disconect{
    font-size: 16px;
    padding: 7px 5px;
    max-width: 120px;
    border: 0px solid #c82333;
    border-radius: 5px;
    color: #fff;
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(226, 59, 59), rgb(231, 70, 70) 74%);
}
.admin__btn-disconect:hover,
.admin__btn-disconect:focus{
    border: 0px solid transparent;
    background-image: linear-gradient(98deg, rgb(173, 93, 101), rgb(177, 100, 108) 74%);
}
.sidebar__item-logo{
    width: 100px;
}
.toggle-btn{
    display: none;
    align-items: center;
    background: #343a40;
    padding: 10px 15px;
    border-radius: 0 5px 0 5px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 88px;
    color: #fff;
    -webkit-box-shadow: 1px -13px 20px rgba(255,255,255,.25), 1px 1px 1px rgba(255,255,255,.1), inset -3px -14px 20px rgba(255,255,255,.1), inset 0 1.8px 0px rgb(0 0 0 / 0%);
    transition: all 0.3s ease;

}
.toggle-btn p{
    margin-right: 5px;
}

.toggle-btn-hide{
    left: 0;
}

.hidden{
    flex: 0;
}

@media screen and (max-width: 1200px) {
    .sidebar__menu {
        /* max-width: 225px; */
        padding-right: 15px;
    }

}

@media screen and (max-width: 991px) {
    .toggle-btn{
        display: flex;
    }
    .sidebar__menu-item:first-child{
        margin-top: 50px;
    }
}

