#mailingPage .card:nth-child(2){
    transition: all .3s ease;
}
.mailing-pages{
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mailing-pages .main-btn:nth-child(2){
    background-color: #1c7e8a;
    box-shadow: 0 4px 24px rgb(28 126 138 / 29%);
}
.mailing-pages .main-btn:nth-child(3){
    background-color: #383f49;
    box-shadow: 0 4px 24px rgb(56 63 73 / 23%);
}
.mailing-pages .main-btn{
    opacity: 1;
}
.selected-item{
    opacity: 0.2 !important;
}
.mailing-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 15px 25px;
    border-radius: 5px;
    border: 2px dashed #31c178;
    cursor: pointer;
}
.mailing-item__desc .main-title{
    margin-bottom: 25px;
}
.mailing-item__desc strong{
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
}
.mailing-item__desc em, .bot-list-item em{
    display: block;
    font-size: 14px;
    font-weight: 100;
}
.mailing-item__desc p{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 100;
    color: #949191;
}
.mailing-item__stat{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mailing-item__stat div{
    margin: 0 50px;
}
.mailing-item__stat div strong{
    display: block;
    font-size: 21px;
    text-align: center;
}
.archived-stat-container{
    padding-bottom: 15px;
    margin: 15px 0;
    border-bottom: 1px solid #343a40;
}
.create-mailing{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.form-card .card{
    padding: 25px;
    box-shadow: 0px 1px 20px 0px rgb(0 0 0 / 8%);
}
.create-mailing .card:first-child{
    width: 100%;
    /* max-width: 800px; */
    /* margin: 25px auto; */
}
.control-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto;
}

.message-field .form-elem:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}

.radio-group {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.radio {
    display: flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
}
.radio span, .checkbox-group span, .radio small{
    display: block;
    margin-left: 5px;
}
.radio-input[type="radio"] {
position: absolute;
opacity: 0;
cursor: pointer;
}
.radio-circle {
position: relative;
width: 16px;
height: 16px;
border: 2px solid #ccc;
border-radius: 50%;
transition: border-color 0.2s ease-in-out;
}
.radio-input[type="radio"]:checked + .radio-circle {
border-color: #206a79;
}
.radio-mark {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 8px;
height: 8px;
background-color: #206a79;
border-radius: 50%;
opacity: 0;
transition: opacity 0.2s ease-in-out;
}
.radio-input[type="radio"]:checked + .radio-circle .radio-mark {
opacity: 1;
} 

#mailingPage .checkbox-group {
    display: flex;
    align-items: center;
  }
  


#mailingPage .checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 250px;
    margin: 0;
    width: 100%;
    margin: 10px 0;
    padding: 7px 0;
    border-radius: 4px;
    background-color: #383f49;
    color: #ffffff;
    cursor: pointer;
}
#mailingPage .checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 250px;
    margin: 0;
    width: 100%;
    margin: 10px 0;
    padding: 7px 0;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;
}
#mailingPage .checkbox:hover{
    opacity: 0.9;
}
  
#mailingPage .checkbox-input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
  }
  
  #mailingPage  .checkbox-fa {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    color: #ffffff;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
  
 
  
  #mailingPage .checkbox-input[type="checkbox"]:checked + .checkbox-box .checkbox-check {
    
    background-color: #206a79;
  }


 
  .file-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
  
  .file-input-wrapper input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-btn, .test-mess-btn{
    display: block;
    max-width: 250px;
    width: 100%;
    margin: 5px 0;
    padding: 7px 0;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #cccccc;
    color: #838383;
    box-shadow: none;
  }
  .file-btn .fa{
    color: #838383;
  }
  
  .file-input-wrapper input[type="file"]:focus + label {
    /* Стили для состояния фокуса */
  }
  
  .file-input-wrapper input[type="file"]:hover + label {
    /* Стили для состояния наведения */
  }

  .add-button-btn{
    margin: 25px 10px;
  }

  .more-mess-btn{
     background: #228442;
  }

  .to-draft-btn{
    vertical-align: sub;
    color: #1c7e8a;
    text-decoration: underline;
    transition: 0.3s ease;
    font-size: 12px;
    
  }
  
  .to-draft-btn:hover{
    color: #a8a8a8;
    background: transparent;
  }

  .save-to-draft-btn, .back-btn{
    color: #4e4e4e;
    background: #f4f4f4;
    border: 1px solid #d9d9d9;
    transition: 0.3s ease;
    
  }
  .save-to-draft-btn:hover, .back-btn:hover{
    background: #a8a8a8;
  }
  .save-to-draft-btn .fa{
    color: #4e4e4e;
    transition: 0.3s ease;
        
  }
  .save-to-draft-btn:hover > .fa {
    color: #fff;
  }
  .delete-mess-btn{
    background-color: #ff5353;
  }
  .delete-mess-btn:hover{
    background-color: #cf3b3b;
  }



  .segments{
    display: inline-block;
    max-width: 325px;
    padding: 5px 10px;
    margin: 5px;
    background: #206a79;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 3px -4px;
  }

  .telegram-link-btn {
    position: relative;
    display: block;
    max-width: 350px;
    margin: 15px 0;
    padding: 10px;
    text-align: center;
    background-color: #383f49;
    color: #fff;
    font-weight: 400;
    border: 1px solid #d9d9d957;
  }
  .telegram-link-btn .fa{
    content: '';
    position: absolute;
    top: 5px;
    right: 2px;
    color: #fff;
    font-weight: 400;
  }
  .telegram-link-btn .delete-link-btn{
    top: -10px;
    right: -10px;
  }
  .create-mail-textarea{
    min-height: 200px;
    max-width: 350px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dbd7d7;
    position: relative;
    padding: 10px 10px;
}
.delete-link-btn{
    position: absolute;
    top: 0;
    right: 10px;
}
.delete-link-btn::after{
    content: '\2716';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    color: #ff5353;
}



.input.invalid {
    border-color: red;
  }

  .file-wrapper {
    position: relative;
    margin-top: 10px;
    margin-left: 50px;
    width: 100px; 
    height: 100px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .image-wrapper {
    position: relative;
  }
  
  .preview-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .delete-button {
    position: absolute;
    top: 0px;
    right: -25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .delete-file {
    color: #c82333;
  }

.phone{
    width: 270px;
    background-color: #171E27;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.phone-screen {
    height: 400px;
    background-color: #f2f2f2;
    margin-top: 20px;
    margin-bottom: 60px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 25px 10px;
    padding-right: 0;
  }
  .chat-avatar {
    flex: 1;
  }
  
  .chat-window {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
    margin-right: 5px;
    flex: 8;
    overflow-y: auto;
    padding: 10px;
    height: 100%;
    font-size: 10px;
  }
  
  .chat-message {
    word-break: break-word;
    overflow-wrap: break-word; 
  }
  .chat-buttons .telegram-link-btn {
    margin: 5px auto;
    padding: 5px 15px;
    font-size: 10px;
}

.chat-files{
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.chat-files .file-wrapper{
    margin: 10px;
    width: 50px;
    height: 50px;
}
.file-inner {
    max-width: 100%;
    max-height: 100%;
    width: 100px;
    height: 100px;
  }

.home-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #f2f2f21f;
    border-radius: 50%;
    bottom: 3%;
    left: 44%;
}
.phone-cam {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #383f49;
    border-radius: 50%;
    top: 3%;
    left: 50%;
}
.chat-window::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-window::-webkit-scrollbar-track {
    background-color: #f2f2f2; 
    border-radius: 4px; 
  }
  
  .chat-window::-webkit-scrollbar-thumb {
    background-color: #383f49;
    border-radius: 4px; 
  }
  
  .chat-window::-webkit-scrollbar-thumb:hover {
    background-color: #383f49c5; 
  }


  

.bot__chanel-col{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.bot__chanel-list{
   display: flex;
}
.bot__chanel-item{
    display: flex;
    justify-content: space-between;
    border: 1px solid #c5bdbd;
    border-radius: 10px;
    width: 100%;
    align-items: center;
}
.bot__chanel-list+.bot__chanel-list{
    margin-top: 10px;
}
.chanel-owner,
.chanel-date{
    font-style: italic;
}
.chanel-users{
    text-align: center;
}
.users-num{
    font-size: 18px;
    font-weight: 700;
}
.users{
    font-size: 20px;
}
.bot__chanel-btn {
    /* background: url(../img/edit.png); */
    background-size: contain;
    background-repeat: no-repeat;
    border:none;
    width: 25px;
    height: 25px;
    border: 1px solid transparent;
    margin-right: 25px;
    cursor: pointer;
}
.bot__chanel-btn:hover{
    border: 1px solid #ebe8e8;
    border-radius: 4px;
}
.bot-modal-title{
    padding-left: 10px;
    font-size: 20px;
    color: #3d7aec;
    padding-top: 10px;
    padding-bottom: 5px;
}
.modal-btn{
    display: flex;
    justify-content: space-between;
}
.modal__btn1{
    font-size: 12px;
    padding: 7px 5px;
    min-height: 15px;
    border: 0px solid transparent;
    border-radius: 5px;
    color: #fff;
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(145, 85, 253) 94%);
} 
.modal__btn1:hover{
    border: 0px solid transparent;
    background-image: linear-gradient(98deg, rgb(170, 149, 207), rgb(172, 145, 235) 94%);
}
.modal__btn2{
    font-size: 12px;
    padding: 7px 5px;
    min-height: 15px;
    border: 0px solid #c82333;
    border-radius: 5px;
    color: #fff;
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(226, 59, 59), rgb(231, 70, 70) 74%);
}
.modal__btn2:hover{
    border: 0px solid transparent;
    background-image: linear-gradient(98deg, rgb(173, 93, 101), rgb(177, 100, 108) 74%);
}
/*========Mailing_create page================*/
select{
    border-radius: 4px;
    font-size: 12px;
}
select:hover,
select:focus{
    border: 1px solid #0b99b280;
    box-shadow: 0px 0px 0px 1px #955ad8;
}

.bot__select-select{
    min-width: 300px;
    min-height: 30px;
    margin-bottom: 30px;
    border: 1px solid #dbd7d7;
}
.login__card-segment{
    margin-bottom: 30px;
}
.bot__mail-grope{
    border: 1px solid #c6c8ca;
    border-radius: 5px;
    display: inline-block;
    padding: 10px;
    padding-right: -50px;
    width: 100%;
}
.bot__select-name{
    position: relative;
    min-width: 100%;
    z-index: 10;
}
.bot__create-mail:focus{
    border: 1px solid #0b99b280;
    box-shadow: 0px 0px 0px 1px #955ad8;
}
.bot__select-surname{
    max-width: 50px;
    position: absolute;
    bottom: 200px;
    right: 20px;
    border: 1px solid #dbd7d7;
}
.bot__select-surname:focus{
    max-width: 120px;
    border: 1px solid #0b99b280;
    box-shadow: 0px 0px 0px 1px #955ad8;
}
.bot__form-add{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.bot__add-form{
    text-align: center;
    width: 98%;
    padding: 5px 0;
    font-size: 12px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #0b99b280;
}
.bot__create-btn {
    font-size: 12px;
    padding: 7px 5px;
    min-width: 120px;
    border: 0px solid transparent;
    border-radius: 5px;
    color: #fff;
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-color: #8778f5;
    margin-left: 10px;
    margin-bottom: 50px;
}
.bot__create-btn:hover{
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-color: #988cf7;
}
.bot__create-lbl {
    font-size: 12px;
    padding: 7px 30px;
    min-width: 120px;
    border: 0px solid transparent;
    border-radius: 5px;
    color: #fff;
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-color: #8778f5;
    margin-left: 10px;
    margin-bottom: 50px;
}
  .feedback__file {
    position: absolute;
    z-index: -1;
    margin-left: -115px;
}
.bot__save-grope{
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.bot__add-save {
    font-size: 12px;
    padding: 7px 5px;
    max-width: 120px;
    border: 1px solid #dbd7d7;
    border-radius: 4px;
    color: #a79e9e;
}
.bot__add-save:hover
{   border: 1px solid transparent;
    background-color: rgb(68, 82, 209);
    background-image: linear-gradient(98deg, rgb(68, 82, 209), rgb(108, 102, 194) 74%);
    color: #fff;
    background-color: #fff;
}
.bot__add-next {
    font-size: 12px;
    padding: 7px 15px;
    max-width: 120px;
    border: 1px solid transparent;
    border-left: 0px solid transparent;
    border-radius: 4px;
    color: #fff;
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(145, 85, 253) 94%);
}
.bot__add-next:hover{
    background-color: rgb(120, 10, 153);
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(120, 10, 153) 94%);
}

/*========Mailing_create2 page================*/
.bot__select-if{
    margin-bottom: 20px;
}
/*========Mailing_create3 page================*/
.bot__mail-grope{
    padding-left: 30px;
    width: 50%;
}
.bot__mailing-groupe{
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 700;
}
.bot__groupe-sel{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.bot__mailing-lbl{
    min-width: 113px;
    display: flex;
    justify-content: flex-end;
}
.bot__mailing-inp{
    width: 250px;
    height: 30px;
    font-size: 14px;
    color: #575454;
    padding: 0 5px;
    outline: none;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
}
.bot__mailing-inp:hover,
.bot__mailing-inp:focus{
    border: 1px solid #0b99b280;
    box-shadow: 0px 0px 0px 1px #955ad8;
}
.bot__mailing-inp::placeholder{
    font-size: 10px;
}
.bot__radio-groupe{
    display: flex;
    column-gap: 20px;
    margin-bottom: 30px;
}
.boy__radio-inner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.bot__radio-input{
    margin-right: 10px;
}
.bot__radio-lbl{
    font-size: 12px;
    font-weight: 700;
}
.bot__radio-label{
    font-size: 12px;
    margin-right: 10px;
}
.radioinp{
    float: left;
  }
.sub_1_radio{
    display: flex;
    flex-direction: column;
    height: 15px;
    max-width: 0;
    overflow: hidden;
}
.radioinp:checked + div{
    min-width: 150px;
    -webkit-transition: all 1s;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 40px;
} 
.radio-date{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.bot__radio-elem{
    display: flex;
    justify-content: flex-start;
}
#customRadio2{
    margin-left: 4px;
}
.bot__will-lbl{
    border: 1px solid #949191;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 30px;
}
.bot__groupe-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}
.bot__groupe-confirm{
    display: flex;
    column-gap: 20px;
}
.bot__create-miling{
    font-size: 12px;
    padding: 7px 5px;
    min-width: 120px;
    border: 0px solid transparent;
    border-radius: 5px;
    color: #fff;
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-color: #8778f5;
}
.bot__create-miling:hover{
    box-shadow: rgba(25, 35, 177, 0.42) 0px 4px 8px -4px;
    background-color: #988cf7;
}
.bot__confirm-save {
    font-size: 12px;
    padding: 7px 5px;
    min-width: 120px;
    border: 1px solid #dbd7d7;
    border-radius: 4px;
    color: #a79e9e;
}
.bot__confirm-save:hover
{   border: 1px solid transparent;
    border-left: 0px solid transparent;
    background-color: rgb(68, 82, 209);
    background-image: linear-gradient(98deg, rgb(68, 82, 209), rgb(108, 102, 194) 74%);
    color: #fff;
    background-color: #fff;
}
.bot__confirm-next {
    font-size: 12px;
    padding: 7px 15px;
    min-width: 120px;
    border: 0px solid #0b99b280;
    border-left: 0px solid transparent;
    border-radius: 4px;
    color: #fff;
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(145, 85, 253) 94%);
}
.bot__confirm-next:hover{
    background-color: rgb(120, 10, 153);
    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
    background-image: linear-gradient(98deg, rgb(198, 167, 254), rgb(120, 10, 153) 94%);
}
/*========Mailing_accept page================*/
.bot__accept{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    column-gap: 5px;
}
.bot__accept-title{
    font-size: 48px;
}
.bot__accept-img{
    width: 140px;
}

@media screen and (max-width: 1200px) {
    .radio-group{
        margin-left: 0;
        display: block;
    }
    .radio-group .radio{
        margin: 10px 0;
    }
    .form-card .card{
        padding: 15px 5px;
    }
    .mailing-body__elem .form-elem:nth-child(2){
        margin: 0;
    }
}


@media screen and (max-width: 768px) {
    .mailing-pages .main-btn{
        padding: 10px;
    }
    #mailingPage .card:nth-child(2){
        padding: 25px 10px !important;
    }
    .select-bot-group .form-elem:first-child{
        flex-direction: column;
        justify-content: flex-start;
    }
    #mailingPage .checkbox-group{
        margin: 0 !important; 
    }
    .select-bot-group .cancel-btn{
        margin: 0 !important;
        padding: 10px;
        width: 100% !important;
    }
    #mailingPage .mailing-body{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 576px) {
    .mailing-pages{
        flex-direction: column;
    }
    .mailing-pages .main-btn{
        margin: 5px;
        width: 100%;
        justify-content: center;
    }
    #botSelect{
        max-width: unset;
    }
    .multiple-bots-container {
        flex-wrap: wrap;
    }
    .multiple-bot{
        margin: 5px;
    }
    .phone{
        max-width: 250px;
        width: 100%;
    }
    .schalude-mail-container{
        
    }
}