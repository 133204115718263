.add {
  border-bottom: 1px dotted;
  font-size: 14px;
  color: #0b99b2;
  cursor: pointer;
  font-weight: 400;
}
.add:hover {
  color: #076779;
}
