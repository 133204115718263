.button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 10px 16px;
  font-size: 15px;
  border: 1px solid #d6e3e3;
  font-weight: 400;
  color: #688fa1;
  outline: 0;
  background-color: #fbfcfc;
  margin: -1px 0;
  border-collapse: collapse;

  position: relative;
  word-wrap: break-word;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}
.button:hover {
  background-color: #e9f6f9;
}
.title {
  color: #0084ff;
}
.link {
  font-size: 12px;
  color: #333;
}
.change {
  color: gray;

  text-align: center;
  display: inline;
  float: right;
  visibility: hidden;
  background-color: #e9f6f900;
  padding: 0px 10px 0px;
  transition: background-color 0.25s ease-in-out;
  font-size: 14px;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
}
.show {
  visibility: visible;
}
.arrows {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -20px;
  justify-content: center;
  top: 0;
  row-gap: 5px;
  opacity: 0.7;
  height: 100%;
}
.arrowsHidden {
  opacity: 0;
}
.arrowsHidden:hover {
  opacity: 1;
}
.arrow {
  background-color: #688fa1;
  color: #fff;
}
.arrow:hover {
  opacity: 1;

  background-color: #557483;
}
