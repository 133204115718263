.block {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.load {
  color: #999;
  text-align: initial;
  padding: 4px 10px 6px 10px;
  border-radius: 10px;
  display: inline-block;
  margin: 2px 0;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.04);
  position: relative;
  font-weight: 900;
  word-break: break-word;
}
.second {
  position: relative;
  padding-left: 40px;
  color: #9db5c4;
  font-size: 16px;
  font-weight: 400;
}
.icon {
  position: absolute;
  top: -4px;
  left: 10px;
  width: 30px;
  height: 30px;
}
