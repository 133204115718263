#editUser .cancel-btn > .fa{
    color: #a52626;
}
#editUser .edit-btn > .fa{
    color: #2698a5;
}

#editUser .form-elem{
    max-width: 500px;
    display: flex;
    align-items: center;
    color: #fff;
}

.edited-title{
    max-width: 120px;
    width: 100%;
    margin-right: 15px;
}
.edited-data{
    max-width: 200px;
    width: 100%;
    overflow: hidden;
    display: block;
    margin-right: 15px;
    text-transform: uppercase;
    
}

.select-focus{
    width: 100%;
}
.show-pass{
    position: absolute;
    right: 10px;
    top: 12px;
    font-weight: bold;
}