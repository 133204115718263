.edgebutton {
  border-radius: 50%;
  background-color: rgb(24, 108, 217);
  width: 20px;
  height: 20px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  position: relative;
}
.crossButton {
  position: absolute;
  top: calc(50% - 16.5px);
  left: calc(50% - 6.7px);
  cursor: pointer;
}
.edgebutton:hover {
  transform: scale(1.1, 1.1);
}
