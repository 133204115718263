.Blocklayout {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;

  height: 100vh;
  min-width: 500px;

  width: 25%;

  overflow-x: hidden;
}

.layout {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.19);

  position: absolute;
  z-index: 100;
  right: 0;
  top: 75px;

  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  overflow: hidden;
}

.popup {
  position: fixed;

  z-index: 110;
  right: 0%;
  top: 75px;
  height: 100vh;
  width: 25%;
  min-width: 500px;
  background-color: #f5f7f7;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: auto;

  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 80px;
  padding-left: 20px;

  /* transform: translateX(500px); */
  transition: transform 0.5s ease-in-out;
}
.animate {
  animation: popupAnimate 0.5s forwards;
  /* transform: translateX(0px); */
}
@keyframes popupAnimate {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0px);
  }
}

.content {
  flex: 1 1 auto;
  padding-right: 20px;
  padding-bottom: 275px;
}
.title {
  text-transform: uppercase;
  font-weight: 700;
  color: #1cacb4;
  padding-bottom: 7px;
  border-bottom: 2px solid rgba(28, 172, 180, 0.6);
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 13px;
}

.buttonSave {
  color: #fff;
  background-color: #31c178;
  border-color: #31c178;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.42857143;
  border-radius: 4px;
  -ms-touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.buttonSave:hover {
  background-color: rgb(44, 170, 107);
  border-color: rgb(44, 170, 107);
}
.buttonSave:active {
  background-color: rgb(41, 157, 99);
  border-color: rgb(37, 139, 88);
}
.blockSave {
  background-color: #f5f7f7;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  min-width: 500px;
  padding: 20px;
  z-index: 10000;
  /* transform: translateX(500px); */
  /* transition: transform 0.5s ease-in-out; */
}
