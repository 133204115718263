.auth-confirm-page{
    position: relative;
    background-image: url('../../../public/bg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}
.auth-confirm-page:before{
    content: '';
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0;
    backdrop-filter: blur(34px);
    background: rgba(37, 36, 36, 0.25);
    width: 100%;
    height: 100%;
}
.auth-confirm-page > .card {
    padding: 30px !important;
    margin: 0 auto !important;
    height: auto !important;
    text-align: center !important;
    background-color: #1D1C1C;
}
.confirm-auth-btns{
    max-width: 280px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-auth-btns .auth-btn:last-child::before{
    background: linear-gradient(148.62deg, rgb(107, 233, 85) 11.713%, rgb(124, 222, 205) 81.542%) !important;
}
.confirm-auth-btns .auth-btn:last-child::after{
    background: linear-gradient(148.62deg, rgb(171, 171, 171) 11.713%, rgb(253, 253, 253) 81.542%) !important;
}