.box {
  background-color: #fff;
  border-radius: 10px;
  min-height: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.title {
  font-weight: 700;
}
