#mailingPage .mailing-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
#mailingPage .mailing-body__elem {
    width: 100%;
    padding: 5px;
}
/* #mailingPage .mailing-body__elem .form-elem:first-child{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
#mailingPage .mailing-body__elem .form-elem:last-child{
   max-width: 250px;
} */
#mailingPage .mailing-body__elem .form-elem small{
    display: block;
    margin-bottom: 15px;
}
#mailingPage .mailing-body__elem .form-elem .radio-label{
    margin-left: 5px;
    margin-bottom: 0;
}
.var-select{
    max-width: 250px;
    width: 100%;
    text-align: left;
}
.mailing-btn{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 250px;
    width: 100%;
    padding: 7px 0;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #1c7e8a;
    box-shadow: none;
    font-size: 13px !important;
}
.mailing-btn:not(:disabled):hover{
    background-color: #707070;
}
.cancel-btn{
    color: #fff;
    background: #8b2f10;
    width: 30%;
}
.select-bot-group .cancel-btn{
  display: block;
  width: 10%;
  margin-left: 15px;
}

.datepicker-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  .datepicker.form-elem{
    margin-left: 0;
    margin-right: auto;
    max-width: 450px;
  }
  .datepicker small{
    width: 100px;
    margin-right: 20px;
  }
  .datepicker-input {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    width: 100px;
    cursor: pointer;
  }
  
  .react-datepicker-wrapper {
    display: block;
  }
  .autodelete-timer-container{
    max-width: 250px;
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .autodelete-timer-container small{
    margin-bottom: 5px !important;
  }
  .autodelete-timer-container button{
    width: 100%;
    color: #fff;
    margin: 5px;
  }
  .autodelete-timer-container button:nth-child(2){
   background-color: #31c178;
  }
  .send-later-btn{
    background-color: #47bb9f !important ;
  }
  .sending-btns{
    margin-top: 50px;
    max-width: 250px;
  }
  .send-later-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .send-later-btns button:first-child{
    width: 50%;
    background-color: #31c178;
  }
  .send-later-btns button:last-child{
    width: 30%;
  }
  .select-bot-group .form-elem:first-child{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  #botSelect{
    max-width: 150px !important;
    width: 100% !important;
  }
  .select-bot-group .form-elem:first-child .checkbox-group{
    margin: 0 15px !important;
  }
  .select-bot-group .form-elem:first-child .checkbox{
    padding: 10px 5px !important;
  }
  .multiple-bots-container{
    display: flex;
    margin-top: 15px;
  }
  .multiple-bot{
    position: relative;
    font-size: 11px;
    padding: 5px;
    padding-right: 20px;
    margin-right: 10px;
    border: 1px solid #eeeff2b4;
    border-radius: 5px;
    background: #eeeff26b;
  }
  .multiple-bot .delete-link-btn{
      position: absolute;
      top: -10px;
      right: -10px;
  }
  .test-mailing-btn{
    background-color: #607d8b;
  }
  @media screen and (max-width: 576px) {

    .datepicker-container{
       flex-direction: column;
       align-items: start;
    }
    .react-datepicker-wrapper{
      margin: 5px 0;
    }
    .send-later-btns button:last-child{
      width: 49%;
    }
}