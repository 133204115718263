.blockFile {
  height: 140px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px dashed rgba(11, 153, 178, 0.35);
  border-radius: 4px;
  background: #fff;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}
.blockFile:hover {
  opacity: 0.9;
}
.item {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 48px 10px 10px;
  position: relative;
  width: 120px;
  margin: 0 auto;
  height: 100px;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
}
.iconFile {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  color: #0b99b2;

  position: absolute;
  left: calc(50% - 12.5px);
  top: 10px;
}
.size {
  font-size: 13px;
  margin-bottom: 4px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.title {
  font-weight: bold;
}
.iconDelete {
  width: 20px;
  height: 20px;

  color: rgb(197, 197, 197);

  position: absolute;
  right: 5px;
  top: 5px;
}
.iconDelete:hover {
  color: gray;
}
