.input,
.input:focus {
  width: 100%;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: #00000006;
}
.tags {
  height: 100%;
  width: 100%;
  flex: 20px 1 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}
.tag {
  padding: 2px 6px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 8px;
  color: rgb(81, 88, 95);
  font-weight: 300;
  cursor: default;
}
.box {
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 75px;
  padding: 8px 8px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cdd4d4;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.remove {
  font-weight: 600;
  color: #6d6d6d;
  font-size: 18px;
  cursor: pointer;
}
.remove:hover {
  color: #2d2d2d;
}
.boxFocus {
  border-color: #1cacb4;
}
