.menu {
  background-color: #74afcc;
  height: 75px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-menu {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  border: 1px solid transparent;
  background: #00829d;
  font-weight: 700;
  color: #fff;
  min-width: 32px;
  margin-right: 10px;
  -webkit-transition: all 42ms ease-in-out 21ms;
  transition: all 42ms ease-in-out 21ms;
  height: 40px;

  background-color: #fff;
  color: #00829d;
}
.button-container .button-menu:nth-child(1),
.button-container .button-menu:nth-child(2){
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}
.button-container .button-menu:nth-child(2) .fa{
  color: #74afcc !important;
}
.button-disabled{
  opacity: 0.2;
}
.button-menu:hover {
  transform: scale(1.05, 1.05);
}
.button-menu:active {
  transform: scale(0.95, 0.95);
  color: #74afcc;
}
.chain-name-container{
  max-width: 600px;
  width: 100%;
  margin-left: 50px;
  margin-right: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.chain-name-container  p{
  color: #fff;
}
.chain-name-container em{
  width: 650px;
  height: 25px;
  overflow: hidden;
  padding: 0 10px;
  text-align: right;
  color: #fff;
  font-size: 18px;
}
.chain-name-input{
  max-width: 450px;
  width: 100%;
  margin-right: 15px;
}
.button-edit{
  color: #f5f7f7;
  font-size: 14px;
  margin: 10px;
  margin-top: 0;
  cursor: pointer;
}


.hamburger-menu {
  margin-right: 15px;
  width: 35px;
  height: 35px;
  position: relative;
  transition-duration: 1s;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.hamburger-menu span {
  height: 3px;
  
  width: 35px;
  background-color:#fff;
  position: absolute;
  transition-duration: .25s;
  transition-delay: .25s;   
}
.hamburger-menu span:before {
  left: 0;
  position: absolute;
  top: 10px;
  height: 3px;
  width: 35px;
  background-color:#fff;
  content: "";
  transition-duration: .25s;
  transition: transform .25s, top .25s .25s;
}
.hamburger-menu span:after {
  left: 0;
  position: absolute;
  bottom: 10px;
  height: 3px;
  width: 35px;
  background-color:#fff;
  content: "";
  transition-duration: .25s;
  transition: transform .25s, top .25s .25s;
}
.hamburger-menu.open span {
  transition-duration: 0.1s;
  transition-delay: .25s;
  background: transparent;
}
.hamburger-menu.open span:before {
  transition: top .25s, transform .25s .25s;
  top: 0px;
  transform: rotateZ(-45deg);
}
.hamburger-menu.open span:after {
 
  transition: top 0.4s, transform .25s .25s;
  top: 0px;
  transform: rotateZ(45deg);
}
.adaptive-buttons__title{
  margin-bottom: 15px;
  font-size: 16px;
  color: #045b6d;
}
.slide-menu {
  position: fixed;
  top: 75px;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #74afcc73;
  padding: 25px;
  transition: 0.5s;
  z-index: 99999;
}
.slide-menu.open {
  right: 0;
}


.slide-menu .button-menu{
  margin: 10px;
  justify-content: center;
}



@media screen and (max-width: 768px) {
  .chain-name-input{
    max-width: 250px;
  }
}

@media screen and (max-width: 576px) {
  .chain-name-input{
    max-width: 200px;
  }
}