.dropDown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  float: left;
  min-width: 200px;
  width: 100%;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.038);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.059);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  padding: 5px;
}
.dropDownElement {
  display: flex;
  justify-content: space-between;
  clear: both;
  font-weight: 500;
  line-height: 1.42857143;
  color: #555555;
  white-space: nowrap;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
}
.dropDownElement:hover {
  color: #444;
  background-color: #31c17930;
}
.dropDownTitle {
  font-size: 14px;
  padding: 0 5px;
  color: #868686;
  white-space: nowrap;
}

.type {
  font-size: 0.6rem;
  opacity: 0.6;
}
.label {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
}
.small {
  max-height: 160px;
  overflow: auto;
}
