.code {
  margin: 2px;
  display: inline-block;
  color: #716064;
  background-color: #eaeaea;
  line-height: 1.1;
  padding: 2px 4px;
  font-size: 90%;
  border-radius: 4px;
}
.codeState {
  color: #c7254e;
  background-color: #f9f2f4;
}
.block {
  border-bottom: 1px dotted #d6e3e3;
  margin-bottom: 4px;
}
.content {
  padding-right: 15px;
}
