.block {
  margin-bottom: 4px;
  position: relative;
  border: 1px solid #d6e3e3;
  border-radius: 6px;
  text-align: left;
  font-size: 13px;
  min-height: 30px;
  padding: 4px;
}
.void {
  color: #999;
}
.text {
  margin-bottom: 3px;
  opacity: 0.7;
  color: #5c6a70;
}
