.photo {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
}
.staticPhoto {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 250px;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.25s ease-in;
}
.hoverPhoto {
  background-color: rgba(0, 0, 0, 0.377);
}
.close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  z-index: 3;
  cursor: pointer;
  font-size: 30px;
  line-height: 0.6;
  color: #fff;
}
.box {
  position: relative;
}
