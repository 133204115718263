.box {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 12px 12px 12px 28px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.boxActive {
  box-shadow: 0 0 0 2px rgba(28, 172, 180, 0.4);
}
.label {
  position: relative;
  cursor: pointer;
}
.description {
  color: #6a6f79;
  font-size: 13px;
}
.radio {
  position: absolute;
  left: -20px;
  top: 3px;
}
