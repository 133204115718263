.error {
  position: absolute;
  top: 100px;
  right: 30px;
  font-size: 14px;
  background-color: rgb(239, 69, 69);
  color: white;
  padding: 3px;
  border-radius: 4px;
  /* display: inline-block; */
  animation-name: fadeIn;
  animation-duration: 1s;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.119);
}
.button {
  font-weight: 590;
  padding: 4px;
  cursor: pointer;
}
@keyframes fadeIn {
  0% {
    transform: translateX(500px);
  }
  60% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}
