.box {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.selectTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.title {
  font-weight: 600;
}
.inputTitle {
}
