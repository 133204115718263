.body {
  background-color: transparent;
  white-space: pre-wrap;
  margin-bottom: 0;
  height: 240px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 6px;
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 15px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #e9eeee;
  border: 1px solid transparent;
  overflow-x: auto;
}
.childStyle {
  color: cadetblue;
  font-weight: 700;
}
