.counter {
  font-size: 12px;
  font-weight: 700;

  position: absolute;
  right: 5px;
  top: -15px;

  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.active {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}
