.switchFile {
  position: absolute;
  z-index: 5;

  left: 12px;
  top: 8px;

  border-bottom: 1px dotted;
  color: #0b99b2;
  cursor: pointer;
  padding-bottom: 3px;

  user-select: none;
}
.switchFile:hover {
  color: #0b8ba1;
}
.caret {
  padding-right: 10px;
}
.caret::before {
  content: '';
  position: absolute;
  top: 0.6em;
  right: -2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  -webkit-transition: all 60ms ease-in-out 30ms;
  transition: all 60ms ease-in-out 30ms;
}
.caretAnimate::before {
  transform: rotate(180deg);
}
.dropDown {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  /* display: none; */
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  border: 0;
  padding: 5px;
}
.dropDownElement {
  display: block;
  clear: both;
  font-weight: 500;
  line-height: 1.42857143;
  color: #555555;
  white-space: nowrap;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
}
.dropDownElement:hover {
  color: #444;
  background-color: #31c17930;
}
