.box {
  display: flex;
  padding: 5px 0;
  column-gap: 15px;
  margin-top: 5px;
}
.btn {
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.15s ease-in-out;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.123);
}
.unactive:hover {
  box-shadow: none;
  cursor: pointer;
}
.add {
  cursor: default;
  background-color: rgb(175, 225, 187);
  color: rgb(9, 201, 89);
  transition: all 0.15s ease-in-out;
}
.delete {
  cursor: default;
  background-color: rgb(225, 175, 175);
  color: rgb(223, 14, 14);
  transition: all 0.15s ease-in-out;
}

.unactive {
  background-color: rgb(229, 229, 229);
  color: rgb(152, 152, 152);
  transition: all 0.15s ease-in-out;
}
.unactive:hover {
  background-color: rgb(210, 210, 210);
}
