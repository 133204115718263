/* react select lib customize */

.css-t3ipsp-control{
  border-color: #206a798a !important;
  box-shadow: 0 0 0 2px #206a7959 !important;
}
.css-t3ipsp-control:hover{
  border-color: #206a798a !important;
}
/* end */

html{
  box-sizing: border-box;
}

*,
*::before,
*::after{
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
html,
body{
  height: 100%;
}
ul,
li{
  list-style: none;
}
button {
  padding: 0;
  border: none;
  font-size: 14px;
  color: inherit;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease;
}
button > img{
  margin-right: 7px;
}
button[disabled] {
  opacity: 0.3;
  cursor:not-allowed;
} 

button[disabled]:hover {
  opacity: 0.3;
}
a{
  display: block;
  text-decoration: none;
  color: inherit;
  
  transition: 0.3s ease;
}
h1, h2, h3, h4, h5, h6{
  /* font-family: "Kanit", sans-serif; */
  text-align: left;
  margin: 0;
  padding: 0;
}
h4{
  display: flex;
  align-items: center;
}
:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
.container{
  max-width: 1420px;
  margin: 0 auto;
  padding: 0 0;
}


.input {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  background-color: #1D1C1C !important;
  border: none;
  border-radius: 4px;
  transition: border-color 0.2s ease-in-out;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none;
}

/* Стили для инпута при фокусе */
.input:focus {
  outline: none;
  border-color: #80EE91;
  box-shadow: 0 0 0 2px #80EE91;
}

input::placeholder {
  color: #fff; 
}


.select-focus .react-select__control:focus,
.select-focus .react-select__control--is-focused {
  border-color: red !important; /* Замените на желаемый цвет обводки */
  box-shadow: 0 0 0 1px red !important; /* Дополнительный стиль для обводки */
  outline: 0 !important; /* Убираем стандартный контур фокуса */
}

label{
  display: block;
  margin-bottom: 5px;
}

textarea {
  margin: 0;
  padding: 0;
  border: none;
  resize: none;
  background: none;
  color: inherit;
  font: inherit;
  text-align: inherit;
  user-select: text;
}




body{
  font-family: "Jura", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #151515;
  font-size: 15px;
}

.d-flex{
  display: flex;
}
.space-between{
  display: flex;
  justify-content: space-between;
  align-items: left;
}
/* h1 */
.main-title{
  margin-top: 70px;
  margin-bottom: 25px;
  font-size: 24px;
  color: #fff;
}
/* h3 */
.card-title{
  font-size: 20px;
  color: #fff;
  text-align: left;
}
.Toastify__toast-body{
  padding: 30px !important;
  padding-right: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.Toastify__toast-body div{
  margin: 5px auto !important;
}

.main-btn {
  position: relative;
  z-index: 10;
  border-radius: 5px;
  margin: 0;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  color: #1D1C1C;
  font-weight: bold;
  transition: all 0.3s ease;
}
.breadcrumbs{
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}
.breadcrumbs::before{
  content: '';
  position: absolute;
  left: 0px;
  top: 5px;
  width: 13px;
  height: 13px;
  margin-right: 10px;
  font-size: 11px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/public/arrow-l.png');
}
.main-btn::before,
.main-btn::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: opacity 0.3s ease;
}

.main-btn::before {
  background: linear-gradient(148.62deg, rgb(171, 171, 171) 11.713%, rgb(253, 253, 253) 81.542%);
  opacity: 0; 
}

.main-btn::after {
  background: linear-gradient(148.62deg, rgb(107, 233, 85) 11.713%, rgb(124, 222, 205) 81.542%);
  opacity: 1; 
  
}

.main-btn:hover::before {
  opacity: 1; 
  
}

.main-btn:hover::after {
  opacity: 0; 
  
}
.action-btn{
  position: relative;
  display: block;
  padding: 7px 10px;
  padding-left: 30px;
  align-items: center;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.main-btn:not(:disabled):hover, .action-btn:not(:disabled):hover{
  background: #717171;

}
.card .main-btn{
  font-size: 14px;
}
.fa {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 10px; 
  color: #1D1C1C;
}
.icon{
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  vertical-align: middle;
}
.dashboard-wrapper{
  display: flex;
  justify-content: center;
}
.scenario-body .card{
  position: relative;
}
.card{
  margin-top: 25px;
  padding: 30px;
  background-color: #252424;
  border-radius: 15px;
  border: 0;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.03);
  box-shadow: 0 1px 2px rgba(0,0,0,.03);
}
.card_inner{
  padding: 20px 25px;
}
.form-card{
  padding: 15px 25px;
  border-radius: 5px;
  border: 2px dashed #31c178;
}
.form-elem{
  margin: 15px 10px;
  position: relative;
}
.form-elem-margin{
  margin-bottom: 25px;
}
.custom-select {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
  color: #333;
}

.custom-select option {
  padding: 10px;
  background-color: #fff;
  color: #333;
}

.error {
  color: red;
  opacity: 1;
  transition: opacity 0.5s, visibility 0s linear 0.5s;
}

.error.fade-out {
  opacity: 0;
  visibility: hidden;
}
.preloader-container{
  position: absolute;
  z-index: 99999;
  padding: 30px;
  border-radius: 15px;
  font-weight: bold;
  background: linear-gradient(148.62deg, rgb(107, 233, 85) 11.599%,rgb(124, 222, 205) 81.723%);
}
.confirm-autn-title{
  color: #fff;
  max-width: 550px;
  margin: 25px auto;
  
}
.preloader-title{
  margin-bottom: 25px;
  display: block;
  text-align: center;
  font-size: 18px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px dotted #3f413f;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    


.alert-container{
  position: relative;
}
#root{
  position: relative;
}

.alert {
  max-width: 290px;
  width: 100%;
  margin: 50px auto;
  border-radius: 4px;
  padding: 25px;
  position: absolute;
  z-index: 1000;
  right: 3%;
  top: -3%;
  cursor: pointer;
  color: #fff;
  opacity: 0.9;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 2.5s ease-in-out;
}
.mailing-alert .alert {
  bottom: 17%;
  top: inherit;
}
.alert-error {
  background-color: red;
}

.alert-success {
  background-color: #107d46;
}
.alert-success::after, .alert-error::after{
  content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    background-image: url('../public/done.png');
    width: 25px;
    height: 25px;
    z-index: 100;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.alert-error::after{
    background-image: url('../public/error.png');
}

.progress-container {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.progress-bar {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #ffffff;
  animation: progressBarAnimation 2s linear;
  transform-origin: left;
}

@keyframes progressBarAnimation {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

/* Анимация для плавного появления и исчезновения */
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
.restore-btn{
  position: absolute;
    z-index: 999;
    top: 2%;
    right: 17%;
    padding: 8px 12px;
    font-size: 15px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 3px;
}

/* Цвет фона скроллбара */
::-webkit-scrollbar-track {
  background-color: #151515;
}

/* Цвет и стиль ползунка скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #878686;
  border-radius: 5px;
}

/* Изменение цвета ползунка при наведении на него курсора */
::-webkit-scrollbar-thumb:hover {
  background-color: #87868691;
  transition: all 0.3s ease;
}
#telegram-login-MailingAdminPanel_bot{
  display: block !important;
  margin: 0 auto;
}


.radio-group {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.radio {
  display: flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}
.radio span, .checkbox-group span, .radio small{
  display: block;
  margin-left: 5px;
}
.radio-input[type="radio"] {
position: absolute;
opacity: 0;
cursor: pointer;
}
.radio-circle {
position: relative;
width: 16px;
height: 16px;
border: 2px solid #ccc;
border-radius: 50%;
transition: border-color 0.2s ease-in-out;
}
.radio-input[type="radio"]:checked + .radio-circle {
border-color: #ffffff;
}
.radio-mark {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 8px;
height: 8px;
background: linear-gradient(148.62deg, rgb(107, 233, 85) 5.8%,rgb(124, 222, 205) 90.861%);
border-radius: 50%;
opacity: 0;
transition: opacity 0.2s ease-in-out;
}
.radio-input[type="radio"]:checked + .radio-circle .radio-mark {
opacity: 1;
} 













@media screen and (max-width: 1200px) {

  .card_inner{
    padding: 0  10px;
  }
  
  #connectBot{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 768px) {
  .main-title{
    font-size: 18px;
  }
  .card-title{
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .main-title{
    font-size: 16px;
    margin-bottom: 25px;
  }
  h4 > p {
    font-size: 14px;
  }
}