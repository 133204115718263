.block {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;

  margin-top: -42px;
  padding: 19px;

  position: relative;
  z-index: 2;

  box-shadow: 0 0 0 3px #fff, 0 2px 8px rgba(0, 0, 0, 0.5);
  text-align: left;

  opacity: 0.1;
  transform: scale(0.7, 0.5);
  transition: all 0.15s ease-in-out;
}
.animate {
  transform: scale(1, 1);

  opacity: 1;
}
.blockText {
  position: relative;
}
.text {
  display: block;
  width: 100%;
  height: 39px;
  padding: 8px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d6e3e3;
  border-radius: 4px;
  font-size: 15px;
  margin-bottom: 5px;
}
.textInvalid {
  border-color: #c13131 !important;
  outline: 0 !important;
}
.blockRadio {
  margin-bottom: 10px;
}
.label {
  margin-right: 30px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  color: #737373;
}
.radio {
  position: absolute;
  margin-left: -20px;
  top: 6px;
}
.blockButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contentButton {
  display: flex;
  column-gap: 5px;
}
.button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.42857143;
  border-radius: 4px;
  -ms-touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.buttonSave {
  color: #fff;
  background-color: #009fc1;
  border-color: #009fc1;
}
.buttonSave:hover {
  background-color: #007f9c;
  border-color: #007f9c;
}
.buttonDelete {
  background-color: #009ec100;
  border-color: #bb364d;
  color: #bb364d;
}
.buttonDelete:hover {
  background-color: #bb364d;
  color: #fff;
}
.addDropDown {
  border-bottom: 1px dotted;
  color: #0b99b2;
  cursor: pointer;
  padding-bottom: 3px;
  display: inline-block;
}
.addDropDown:hover {
  color: #00708a;
}
