.headBox {
  background-color: transparent;
  white-space: pre-wrap;
  margin-bottom: 0;
  height: 240px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 6px;
  font-size: 15px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #e9eeee;
  border: 1px solid transparent;
  overflow-x: auto;
}
.headEntity {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #cdd4d4;
}
.headerKey {
  word-break: keep-all;
  min-width: 150px;
}

.table {
  border-collapse: collapse;
}
