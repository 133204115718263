.fileBlock {
  margin-bottom: 15px;
  position: relative;
}
.textBlock {
  padding: 28px 24px 24px;
  background-color: #f2f6f9;
}
.textContent {
  position: relative;
}
