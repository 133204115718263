.dropZone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 140px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px dashed rgba(11, 153, 178, 0.3);
  border-radius: 4px;
  color: #0b99b2;
  background: #f6fcff;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  transition: all 150ms ease;

  position: relative;
}
.dropZone:hover {
  background: #d7f2ff;
}
.dropZoneDrag {
  border: 1px dashed rgba(11, 156, 96, 0.3);
  background: #d7ffec;
}
.iconPhoto {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
}
.chooseFile {
  padding: 5px 10px;
  background: #5bc0de;
  border-radius: 2px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  border: 0;
  outline: 0;
}
.title {
  width: 100%;
  display: block;
  text-align: center;
  color: #0b99b2;
  font-weight: 400;
  font-size: 12px;
  padding-top: 9px;
}
.warning {
  text-align: left;
  position: absolute;
  font-size: 13px;
  left: 5px;
  bottom: 5px;
  color: #688fa1;
  z-index: 1;
  opacity: 0.6;
}
.iconWarning {
  color: #f28c4b;
  width: 17px;
  height: 17px;
  margin-right: 3px;
  position: relative;
  bottom: -3px;
}
