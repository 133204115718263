.block {
  width: 230px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.813);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: 5px;
}

.blockSelected {
  border-color: #31c178;
  box-shadow: 0 0 0 1px #31c178, 0 0 0 4px rgba(49, 193, 120, 0.3);
}
.title {
  display: flex;
  justify-content: center;
  padding: 2px 5px 5px 5px;
  background-color: #af5ec7;

  color: #ffffff;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}
.description {
  color: #9db5c4;

  font-weight: 500;
  text-align: center;
  font-size: 16px;
  text-align: center;
}
.descriptionMain {
  font-weight: 500;
  color: #465152;
  text-align: center;
  font-size: 16px;
}
.contentBlock {
  color: grey;
  padding: 6px 4px;
  font-size: 13px;
}

.gray {
  padding: 0 5px;
  background-color: #f0f0f0;
  border-radius: 3px;
  font-weight: 400;
  color: #465152;
  border-radius: 4px;
  margin: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 2px;
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.secondaryText {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #465152;
}
.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
}
.tag {
  padding: 0 5px;
  background-color: #f0f0f0;
  border-radius: 3px;
  font-weight: 400;
  /* min-height: 20px; */
  margin-top: 4px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
