
.stat-date {
    margin: 5px auto;
    display: flex;
    flex-wrap: wrap;
}
.stat-date .react-datepicker-wrapper{
    margin-bottom: 0 !important;
}

.filter-btn{
    padding: 7px 10px;
    margin: 0;
}
.stat-data{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    
    margin-top: 25px;
}
.stat-data__item{
    width: 100%;
    max-width: 225px;
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgb(195 194 194);
    text-align: center;
}
.stat-data__item strong{
    display: block;
    margin-top: 15px;
    font-size: 19px;
}
.stat-data-item__title{
    height: 50px;
   margin-top: 15px;
}