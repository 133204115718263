.textArea {
  display: block;
  width: 100%;
  min-height: 100px;

  padding: 5px 5px 5px 5px;
  overflow: auto;

  resize: vertical;
  font-size: 15px;
  line-height: 1.42857143;
  color: #555;
  border: 1px solid #d6e3e3;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  position: relative;
}
.textArea:focus {
  border-color: #31c178;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 0 0 2px rgba(49, 193, 120, 0.2);
  z-index: 2;
}
