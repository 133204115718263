.button {
  display: inline-block;
  border: 1px solid #0084ff;
  background: #fff;
  border-radius: 1.3em;
  color: #0084ff;
  padding: 2px 30px 2px 10px;
  text-align: center;
  position: relative;
  word-break: break-word;
}
