.graphTitle {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color: #023346;
  margin-top: 10px;
  font-family: inherit;
  line-height: 1.1;
}
.titleBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.helpBlock {
  margin: 0 0 10px;
  color: #6a6f79;
  font-size: 14px;
}
