.block {
  top: 0;
  z-index: 10000;
  position: absolute;
  background-color: rgba(240, 248, 255, 0.431);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  color: #74afcc;
  width: 50px;
  height: 50px;
  animation: spinner 1s infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  0% {
    transform: rotate(-360deg);
  }
}
