.box {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  border-bottom: 1px solid #cdd4d4;
}
.btnBox {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  color: #023346;
}
.btn {
  display: block;
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 transparent;
  transition: all 30ms ease-in-out 5ms;
}
.btnBox:hover {
  box-shadow: inset 0 -3px 0 rgba(0, 177, 117, 0.2);
}
.btnAct {
  box-shadow: inset 0 -3px 0 #00b175;
  color: #00b175;
}
