.blockSelect {
  padding: 5px 0px;
  font-size: 13px;
}
.titleRow {
  color: #5c6a70;
  position: relative;
}
.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 2px 18px 2px 5px;
  margin: 0;
  font-size: 1em;
  color: #0b99b2;
  position: relative;
  cursor: pointer;
}
.titleRow::after {
  content: '▲';
  color: #0b99b2;
  display: block;
  position: absolute;
  right: -25px;
  top: 2px;
  transform: scale(1, 0.4) rotate(180deg);
}
