.addDropDown {
  border-bottom: 1px dotted;
  color: #0b99b2;
  cursor: pointer;
  padding-bottom: 3px;
  position: relative;
  user-select: none;
}
.addDropDown:hover {
  color: #0b8ba2;
  border-color: #0b8ba2;
}
.addDropDowAnimate {
  color: #f25a3e;
  border-color: #f25a3e;
  border-bottom: 1px dotted;
  cursor: pointer;
  padding-bottom: 3px;
  position: relative;
  user-select: none;
}
.caret {
  position: relative;
  padding-right: 10px;
}
.caret::before {
  content: '';
  position: absolute;
  top: 0.6em;
  right: -2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  -webkit-transition: all 60ms ease-in-out 30ms;
  transition: all 60ms ease-in-out 30ms;
}
.caretAnimate::before {
  transform: rotate(180deg);
}

.dropDownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  list-style: none;
  font-size: 15px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  /* min-width: 320px; */
  max-height: 300px;
  background-color: #fbfcfc;
  transition: all 90ms ease-in-out 45ms;
  overflow: hidden;
  opacity: 1;
  padding: 15px 15px 10px;
  margin: 6px 0 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  transform: scale(1, 0.5) translateY(-100px);
  transition: transform 150ms ease-in-out;
}
.dropDownElement {
  display: block;
  position: relative;

  height: 72px;
  width: 90px;

  padding-top: 41px;

  text-align: center;
  color: #333;

  cursor: pointer;
  font-size: 13px;
  transition: all 100ms ease-in-out 15ms;
  background-color: #fff;

  border-radius: 6px;
  border: 0;

  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
  user-select: none;
}
.dropDownElement:hover {
  transform: scale(1.1, 1.1);
}
.dropDownElement:active {
  transform: scale(0.85, 0.85);
}
.animate {
  transform: scale(1, 1) translateY(0);
}
.icon {
  position: absolute;
  top: 10px;
  left: calc(50% - 15px);

  font-weight: 100;
  stroke-width: 1.5;
  color: #0b99b2;
  width: 30px;
  height: 30px;
}
