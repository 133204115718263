.block {
  width: 150px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.813);
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: grab;
  padding: 5px;
}
.blockSelected {
  border-color: #31c178;
  box-shadow: 0 0 0 1px #31c178, 0 0 0 4px rgba(49, 193, 120, 0.3);
}
.title {
  display: flex;
  justify-content: center;
  padding: 2px 5px 5px 5px;
  background-color: tomato;
  background-color: cadetblue;

  color: #ffffff;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}
.description {
  color: #9db5c4;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 10px;
}
.contentBlock {
  color: grey;
  padding: 6px 4px;
  font-size: 13px;
}

.continue {
  color: #9db5c4;
  text-align: right;
  padding-right: 7px;
}
.robot {
  width: 50px;
  height: 50px;
  color: #ff4d2e;
  color: cadetblue;
  position: relative;
  left: calc(50% - 25px);
  animation: alex 2s alternate 0s infinite backwards normal;
}
