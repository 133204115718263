.buttonGroup {
  /* background-color: rgb(244, 244, 244);
  display: flex;
  flex-direction: column; */

  display: flex;
  flex-direction: column;
  height: calc(100%);
  background: #f5f7f7;
  position: relative;
  max-width: 100px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
  z-index: 1;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
}
.disabledButtons {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.097);
}
.buttonNode {
  text-align: center;
  font-size: 12px;
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
  -webkit-touch-callout: none;
  font-family: "Nunito Sans", "Avenir Next", "Segoe UI", "Open Sans",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #5c6a70;
  background-color: #fff;
  border-radius: 6px;
  border: 1px #fff solid;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  position: relative;
  padding: 48px 6px 10px;
  min-height: 75px;
  /* width: 80%; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-position: center 10px !important;
  background-repeat: no-repeat !important;
}
.buttonNode:hover {
  border-color: #fff;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.buttonNode:active {
  border: 1px dashed #eaeaea;
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #999;
}

.message {
  background-image: url(../../icon/message.svg) !important;
}
.pause {
  background-image: url(../../icon/pause.svg) !important;
}
.filter {
  background-image: url(../../icon/filter.svg) !important;
}
.chain {
  background-image: url(../../icon/chain.svg) !important;
}
.captcha {
  background-size: 60px !important;
  background-position: 20px 5px !important;
  background-image: url(../../icon/captcha.svg) !important;
}
.api {
  background-image: url(../../icon/api.svg) !important;
}
.action {
  background-image: url(../../icon/action.svg) !important;
}
::-webkit-scrollbar {
  width: 1px; /* Ширина вертикального скролу */
}
