.box {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f7f7;
  margin: 5px 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
.btnContainer {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: 100%;
}
