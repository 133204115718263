.arrows {
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  position: absolute;
  right: -20px;
  justify-content: flex-start;
  top: 0;
  opacity: 0.7;
  height: 100%;
  width: 25px;
}
.trash {
  width: 15px;
  height: 15px;
  color: rgb(216, 33, 45);
  transform: scale(1.5, 1.5);
  margin: 3px 0 15px 0;
  cursor: pointer;
}
.trash:hover {
  color: red;
}
.arrow {
  color: #485c91;
  border: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.up > path:last-child {
  stroke-width: 1;
}
.down > path:last-child {
  stroke-width: 1;
}
.arrow:hover {
  color: #0b99b2;
}
.up {
  transform: scale(1, 1.2) rotate(90deg);
}
.down {
  transform: scale(1, 1.2) rotate(270deg);
}
