.botlist-item{
    cursor: pointer;
    color: #000;
    font-weight: bold;
    padding: 20px;
    margin: 25px 0;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: linear-gradient(148.62deg, rgb(107, 233, 85) 14.533%,rgb(124, 222, 205) 77.099%);
}
.botlist-item-title{
    display: inline;
    padding: 10px 15px;
    color: #fff;
    font-weight: 100;
    font-size: 15px;
    background: #000;
    border-radius: 5px;
    text-transform: capitalize;
}

.botlist-item strong:hover{
   color: #ffffff96;
}
.botlist-item p{
    margin: 5px 0;
    font-size: 12px;
    letter-spacing: 0;
}
.botlist-item__stats{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.botlist-item__stats span{
    font-size: 28px;
}
.botlist-item__stats p{
    margin-top: 0;
    text-transform: lowercase;
}
.stats-item{
    max-width: 80px;
    width: 100%;
    margin-right: 20px;
}

@media screen and (max-width: 1200px) {
    .botlist-item{
        max-width: 600px;
        margin: 25px auto;
    }
    .botlist-item__stats{
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: start;
    }
    .botlist-item__stats-item{
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 768px) {
    .botlist-item{
        max-width: 400px;
        margin: 25px auto;
    }
    .botlist-item__stats-item {
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 130px;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .botlist-item__stats{
        justify-content: center;
    }
}