.box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  min-height: 20px;
  padding: 5px;
}
.container {
  position: relative;
}
.delete:hover {
  color: rgb(199, 0, 0);
}
.delete {
  cursor: pointer;
  color: gray;
}
